import React, { useContext } from "react";
import InputContext from "../context";
import inputDefaults from "../context/defaults";
import { useLocalStorage, useQueryString } from "../hooks";

const ResetAllInputs = () => {
  const { setInput } = useContext(InputContext);
  const [, setLocalStorage] = useLocalStorage("inputs");
  const [, setQueryString] = useQueryString();

  const resetInputs = () => {
    setLocalStorage({});
    setInput({ ...inputDefaults });
    setQueryString({});
  };

  return (
    <button className="btn" style={{color: '#b52b39'}} onClick={resetInputs}>
      Reset All Inputs
    </button>
  );
};

export default ResetAllInputs;
