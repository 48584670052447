import ASSUMPTIONS from "../calcs/ASSUMPTIONS";

export default [
  {
    question: "What can I expect to get out of this tool?",
    answer:
      "The comparison between conventional gas/diesel vehicles and electric vehicles can be complex. This tool simplifies the process, requiring you enter only a minimum amount of information. The tool performs a variety of calculations in the background to provide you an approximation of fuel savings when going electric based on information you provide and additional assumptions."
  },
  {
    question:
      "Do I have to be an expert in EVs or utility rates to use this tool?",
    answer:
      "Not at all. You only need to understand your current fleet. When you enter information about your fleet, the tool identifies the EV tariff most likely to reflect your EV charging needs and calculates the electric fueling costs. Simply entering the information at the top of the page is all it takes. If you want to enter more details about your fleet, you can use the Advanced Customization section, but it is not required."
  },
  {
    question: "What information is needed from me in order to use this tool?",
    answer: `To get an approximation of fuel savings, simply enter:
      <ul>
        <li>Type of vehicle you are considering to adopt or convert to an electric vehicle</li>
        <li># of vehicles you are considering to adopt or convert to an electric vehicle</li>
        <li>Average daily mileage those vehicles will drive and how many days of the week they will be in use</li>
      </ul>
      `
  },
  {
    question:
      "What are the assumptions that this tool uses to get the maximum savings?",
    answer:
      "Based on your fleet characteristics and charging schedule, this tool suggests what type of electric vehicle charger can meet your needs."
  },
  {
    question: "Can I look at multiple vehicle types at the same time?",
    answer:
      "At this time, this tool provides a comparison between only one type of conventional versus electric vehicle at a time. If you need assistance evaluating a fleet with multiple vehicle types, please contact us."
  },
  {
    question:
      "What if I have multiple facilities where I will electrify my fleet?",
    answer:
      "You can change any of the inputs to evaluate different facilities, but only one facility and vehicle type at a time. If you need assistance evaluating a fleet across multiple locations, please contact us."
  },
  {
    question: "What vehicles are included in this tool?",
    answer: "Diesel vehicles from Class 3 to Class 8"
  },
  {
    question: "What if my vehicle type isn't listed as an option?",
    answer:
      "Please contact us to assist you in exploring electrification opportunities of other vehicle types."
  },
  {
    question: "Does this tool address the total cost of owning a vehicle?",
    answer:
      "At this time, this tool focuses on the potential fuel cost savings and Low Carbon Fuel Standard credits only. Please contact us if total cost of ownership is of high importance to you, and we may add additional assumptions in the near future."
  },
  {
    question: "Is Diesel Exhaust Fluid (DEF) considered in savings?",
    answer:
      "Yes, the tool assumes that diesel vehicles have a DEF dosing rate of 3% with a cost of $2.80/gallon."
  },
  {
    question: "Which Transport Refrigeration Units are included in this tool?",
    answer:
      "This tool allows comparing standard conventional TRUs to hybrid electric TRUs"
  },
  {
    question: "What information is needed to assess TRU fuel savings?",
    answer:
      "You simply need to enter the number of hours that your conventional TRU is typically running per day and how many of those hours a hybrid TRU could be plugged in. Within the Advanced Customization section, you can also specify how many gallons of diesel your conventional TRU uses daily as well as the maximum power draw (kW) from a hybrid TRU. Most hybrid TRUs will operate using standby charging equipment that can accommodate up to 24.9 kW."
  },
  {
    question:
      "What scenarios of TRU use does this tool address: hybrid TRU or fully electric EV with electric TRU?",
    answer:
      "This tool compares the trailer only of either a diesel TRU or a hybrid TRU. If you are interested in learning more about conversion savings for a fully electric TRU, please contact us."
  },
  {
    question: "Will there need to be infrastructure changes?",
    answer:
      'There may need to be infrastructure changes depending on your specific site requirements (e.g. need for trenching, conduit). SCE is able to provide support, including the full design and construction. For more info, check out our <a href="https://www.sce.com/business/electric-cars/charge-ready-transport" target="_blank" rel="noopener">Charge Ready Transport</a> program or contact us.'
  },
  {
    question: "What rates are available for electric vehicle charging?",
    answer:
      "Depending on usage, the following are EV-specific tariffs available for fleet customers: TOU-EV-7, TOU-EV-8, and TOU-EV-9. The tool applies the EV Tariff that aligns with the maximum demand from the electric vehicle chargers. Please contact us if you have questions regarding any other rate options."
  },
  {
    question: "Will there be demand charges?",
    answer:
      "Demand charges for customers on TOU-EV-7, TOU-EV-8, and TOU-EV-9 are scheduled to phase-in starting in 2024 and are projected to be 60% of the facilities related demand charge by 2029."
  },
  {
    question: "What are fixed, demand, and energy charges?",
    answer:
      "Fixed charges do not vary month to month and are a basic customer charge. Demand (kW) charges are based on your highest 15-minute power consumption during the billing period. Energy (kWh) charges are based on how much energy you consume during each of the Time-of-Use periods."
  },
  {
    question: "What is LCFS?",
    answer:
      'The Low Carbon Fuel Standard (LCFS) is a program facilitated by the California Air Resources Board (CARB) to decrease the carbon intensity of California\'s transportation fuel. Fleet owners earn LCFS credits by operating electric vehicles. These credits can be sold on the credit market to earn revenue for fleets. More information can be found on CARB\'s website <a href="https://ww2.arb.ca.gov/our-work/programs/low-carbon-fuel-standard" target="_blank" rel="noopener">here</a>.'
  },
  {
    question: "How do you estimate the LCFS Credit Price?",
    answer: `Our tool uses a price of ${
      "$" + ASSUMPTIONS.LCFS_PRICE_IN_DOLLARS_PER_CREDIT
    }/credit, which reflects recent <a href="https://ww3.arb.ca.gov/fuels/lcfs/dashboard/dashboard.htm" target="_blank" rel="noopener">trends</a> of LCFS Credit Prices. This credit price is not a guarantee and may fluctuate.`,
  },
  {
    question: "Are electric trucks & buses eligible for LCFS credits?",
    answer:
      "Yes. Because adopting electric vehicles results in replacing carbon-based fuels with lower carbon fuel sources, such as electricity, when you adopt and charge electric vehicles your organization is eligible for LCFS credits, which are estimated on the right-hand side of the Fuel Savings Calculator Tool web page.  If you would like more information about LCFS eligibility for EVs contact us."
  },
  {
    question:
      "What other resources are available to learn more about medium/heavy-duty electric vehicles and hybrid TRUs?",
    answer: `SCE links include
      <ul>
      <li><a href="https://sce.com/crt" target="_blank" rel="noopener">Charge Ready Transport Program Homepage</a></li>
      <li><a href="/ev_charging_guidebook.pdf" target="_blank" rel="noopener">EV Charging Guidebook</a></li>
      <li><a href="https://www.sce.com/business/electric-cars/charging-stations-for-fleet" target="_blank" rel="noopener">Fleet Charging</a></li>
      </ul>
    `
  }
];
