import React from "react";
import TopNav from "../header/TopNav";
import { Row, Col } from "../styles";
import { colors } from "../styles";
import Footer from "../header/Footer";
import { default as BaseFaqItem } from "../styles/FaqItem";
import faqs from "../data/faqs";

const FaqContainer = ({ children }) => (
  <div
    id="faq_container"
    style={{
      width: "100%",
      borderRadius: "8px",
      backgroundColor: colors.white,
      boxShadow: "0 16px 34px 7px rgba(121,121,121,0.1)",
      padding: "72px 0",
      marginBottom: "40px"
    }}
  >
    {children}
  </div>
);

const Header = ({ children }) => (
  <div
    style={{
      fontSize: "28px",
      fontWeight: 500,
      color: colors.darkGrey,
      margin: "40px 0"
    }}
  >
    {children}
  </div>
);

const FaqItem = faq => (
  <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
    <Col xs={{ size: 10, offset: 1 }}>
      <BaseFaqItem {...faq} />
    </Col>
  </Row>
);

const Faq = () => (
  <>
    <TopNav active="faq" />
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <Header>Frequently Asked Questions</Header>
        <FaqContainer>
          {faqs.map(faq => (
            <FaqItem key={faq.question} {...faq} />
          ))}
        </FaqContainer>
      </Col>
    </Row>
    <Footer />
  </>
);

export default Faq;
