import React from "react";
import { colors } from "../styles";

const CheckButton = ({ checked, children, onClick }) => (
  <div
    onClick={onClick}
    role="checkbox"
    aria-checked={checked}
    tabIndex="0"
    aria-label={children}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      width: "100%",
      cursor: "pointer",
      borderRadius: "5px",
      border: "1px solid",
      borderColor: checked ? colors.primary : colors.lightGrey,
      backgroundColor: checked ? colors.primary : "transparent",
      color: checked ? colors.white : colors.primary
    }}
  >
    {children}
  </div>
);

export default CheckButton;
