import React from "react";
import { colors } from "../../styles";

export const Header = ({ children }) => (
  <div
    style={{
      color: colors.darkGrey,
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center"
    }}
  >
    {children}
  </div>
);

export const Subheader = ({ children }) => (
  <div
    style={{
      color: colors.lightGrey,
      fontSize: "14px",
      textAlign: "center"
    }}
  >
    {children}
  </div>
);

export const Label = ({ children }) => (
  <div
    style={{
      margin: "20px 0"
    }}
  >
    {children}
  </div>
);
