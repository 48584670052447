import React, { useContext, useState } from "react";
import InputContext from "../context";
import { Modal } from "../styles";
import sendEmail from "../utils/sendEmail";
import { Row, Col } from "../styles";
import { Header, Label, Subheader } from "./content/styles";
import { Text } from "../inputs";
import { colors } from "../styles";

const requiredInputs = ["firstName", "lastName", "phone", "email", "company"];

const SkipLink = props => (
  <button
    className="btn btn-link btn-xs"
    style={{ color: colors.lightGrey }}
    {...props}
  >
    Skip for now
  </button>
);

const ContactModal = ({ isOpen, toggle, firstVisit = false }) => {
  const { setInput, ...inputs } = useContext(InputContext);
  const [submitted, setSubmitted] = useState(false);
  const { firstName, lastName, email, phone, company } = inputs;

  const hasRequiredInputs = () => requiredInputs.every(input => inputs[input]);

  const callEmailApi = () => {
    setSubmitted(true);
    if (hasRequiredInputs()) {
      sendEmail(inputs);
      toggle();
    }
  };

  return (
    <Modal
      title="Contact"
      isOpen={isOpen}
      status={firstVisit ? "Step 3 of 3" : ""}
      toggle={toggle}
      size="lg"
      fade={!firstVisit}
      onSubmit={callEmailApi}
      submit="Submit"
    >
      <Label>
        <Header>What is your contact information?</Header>
        <Subheader>
          SCE can help you find the best savings for your fleet.
        </Subheader>
        <Subheader>
          All customer information is treated according to{" "}
          <a
            href="https://www.sce.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            SCE’s Privacy Policy
          </a>
          .
        </Subheader>
      </Label>

      <Row>
        <Col xs="12" sm>
          <Text
            label="First Name"
            value={firstName}
            error={submitted && !firstName}
            onChange={firstName => setInput({ firstName })}
          />
        </Col>
        <Col xs="12" sm>
          <Text
            label="Last Name"
            value={lastName}
            error={submitted && !lastName}
            onChange={lastName => setInput({ lastName })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm>
          <Text
            label="Email Address"
            value={email}
            error={submitted && !email}
            onChange={email => setInput({ email })}
          />
        </Col>
        <Col xs="12" sm>
          <Text
            label="Phone Number"
            value={phone}
            error={submitted && !phone}
            onChange={phone => setInput({ phone })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm>
          <Text
            label="Company"
            value={company}
            error={submitted && !company}
            onChange={company => setInput({ company })}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end"
          }}
        >
          <SkipLink onClick={toggle} />
        </Col>
      </Row>
    </Modal>
  );
};

export default ContactModal;
