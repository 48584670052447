import React, { useContext } from "react";
import InputContext from "../context";
import Accordion from "../styles/Accordion";
import { Slider, Select, ChargingSchedule, Lcfs } from "../inputs";
import VEHICLES from "../data/VEHICLES";
import EVSE from "../data/EVSE";
import KEYS from "../calcs/KEYS";
import { colors } from "../styles";
import { Row, Col } from "reactstrap";
import ResetAllInputs from "./ResetAllInputs";
import { FormatAsThousands } from "../utils/Format";

const SecondaryInputs = ({ outputs }) => {
  const {
    setInput,
    vehicleType,
    chargingSchedule,
    includeLcfs,
    evseType,
    vehicleToChargeportRatio,
    energySource,
    dieselPrice,
    truDieselGalPerDay,
    truOperatingHrsPerDay,
    truPowerDrawInKw,
    customConventionalVehicleType,
    customConventionalVehicleMilesPerGal,
    customElectricVehicleType,
  } = useContext(InputContext);

  const recommendedPanel =
    outputs.modelErrors.canRecommendEvse && outputs.evseRecommended ? (
      <div className="card" style={{ width: "100%" }}>
        <div className="card-header">
          <p className="card-text text-center">Recommended</p>
        </div>
        <div className="card-body">
          <p className="card-text">
            EVSE:
            <span className="float-right">
              {outputs.evseRecommended.evseType}
            </span>
          </p>
          <p className="card-text">
            EV Facility Peak:
            <span className="float-right">
              {FormatAsThousands(outputs.chargeRateInKw * outputs.evseCount)} kW
            </span>
          </p>
        </div>
      </div>
    ) : null;

  return (
    <div className="mt-sm-n5 d-print-none">
      <Accordion title="Advanced Options">
        <Accordion.Section
          defaultOpen={true}
          isHidden={!outputs.isTru}
          title="TRU"
        >
          <Slider
            label="TRU Gallons per Day"
            min={1}
            max={20}
            value={truDieselGalPerDay}
            onChange={(truDieselGalPerDay) => setInput({ truDieselGalPerDay })}
          />
          <Slider
            label="TRU Operating Hours / Day"
            min={1}
            max={24}
            tooltip="How long is your TRU running daily (includes precooling, loading, driving, standyby while waiting for a driver, and unloading)."
            value={truOperatingHrsPerDay}
            onChange={(truOperatingHrsPerDay) =>
              setInput({ truOperatingHrsPerDay })
            }
          />
          <label style={{ color: colors.lightGrey }}>
            Operating hours should always be greater than the total number of
            charging hours ({chargingSchedule.length}).
          </label>
          <Slider
            label="TRU Max Power Draw (kW)"
            min={1}
            max={25}
            step={0.5}
            value={truPowerDrawInKw}
            onChange={(truPowerDrawInKw) => setInput({ truPowerDrawInKw })}
          />
        </Accordion.Section>
        <Accordion.Section
          defaultOpen={true}
          isHidden={vehicleType !== KEYS.CUSTOM}
          title="Custom"
        >
          <Select
            label="Conventional Vehicle Type"
            options={[
              ...Object.keys(VEHICLES).map((value) => ({
                key: value,
                option: value,
              })),
              { key: KEYS.CUSTOM, option: KEYS.CUSTOM },
            ]}
            value={customConventionalVehicleType}
            onChange={(customConventionalVehicleType) =>
              setInput({ customConventionalVehicleType })
            }
          />
          {customConventionalVehicleType === KEYS.CUSTOM && (
            <Slider
              label="Conventional Vehicle MPG"
              min={1}
              max={20}
              step={0.5}
              value={customConventionalVehicleMilesPerGal}
              onChange={(customConventionalVehicleMilesPerGal) =>
                setInput({ customConventionalVehicleMilesPerGal })
              }
            />
          )}
          <Select
            label="Electric Vehicle Type"
            options={[
              ...Object.keys(VEHICLES).map((value) => ({
                key: value,
                option: value,
              })),
            ]}
            value={customElectricVehicleType}
            onChange={(customElectricVehicleType) =>
              setInput({ customElectricVehicleType })
            }
          />
        </Accordion.Section>
        <Accordion.Section
          defaultOpen={true}
          isHidden={outputs.isTru}
          title="Charging"
        >
          <Select
            label="EV Charger Type"
            options={[
              { option: KEYS.RECOMMEND, key: KEYS.RECOMMEND },
              ...Object.keys(EVSE).map((value) => ({
                key: value,
                option: value,
              })),
            ]}
            value={evseType}
            onChange={(evseType) => setInput({ evseType })}
          />
          <label style={{ color: colors.lightGrey, width: "100%" }}>
            {recommendedPanel}
          </label>
          <Slider
            label="Vehicle to Charge-Port Ratio"
            min={1}
            max={4}
            value={vehicleToChargeportRatio}
            onChange={(vehicleToChargeportRatio) =>
              setInput({ vehicleToChargeportRatio })
            }
          />
          <label style={{ color: colors.lightGrey }}>
            This is the equivalent of {outputs.evseCount} installed chargers.
          </label>
        </Accordion.Section>
        <Accordion.Section title="Charging Schedule">
          <ChargingSchedule
            value={chargingSchedule}
            onChange={(chargingSchedule) => setInput({ chargingSchedule })}
          />
        </Accordion.Section>
        <Accordion.Section title="Diesel">
          <Slider
            label="Diesel Price ($/gal)"
            min={2}
            max={20}
            step={0.25}
            prefix="$"
            value={dieselPrice}
            onChange={(dieselPrice) => setInput({ dieselPrice })}
          />
        </Accordion.Section>
        <Accordion.Section title="LCFS">
          <Lcfs
            value={includeLcfs}
            onChange={(includeLcfs) => setInput({ includeLcfs })}
          />
          <Select
            label="Energy Source"
            tooltip="Fleets can yield higher value from LCFS credits if energy supply is cleaner than California's average energy mix (standard)."
            options={[
              { option: KEYS.GRID, key: KEYS.GRID },
              { option: KEYS.ZERO_CI, key: KEYS.ZERO_CI },
            ]}
            value={energySource}
            onChange={(energySource) => setInput({ energySource })}
          />
        </Accordion.Section>
      </Accordion>
      <Row>
        <Col className="text-center mt-3">
          <ResetAllInputs />
        </Col>
      </Row>
    </div>
  );
};

export default SecondaryInputs;
