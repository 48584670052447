import React, { useContext } from "react";
import InputContext from "../context";
import inputDefaults from "../context/defaults";
import { Link as BaseLink } from "react-router-dom";
import { useToggle } from "@bellawatt/react-hooks";
import ContactModal from "../modals/ContactModal";
import { colors, Row, Col } from "../styles";
import TopNav from "../header/TopNav";
import Footer from "../header/Footer";
import KEYS from "../calcs/KEYS";

const IMAGE_CONTAINER_HEIGHT = "600px";

const ImageContainer = ({ children }) => (
  <Col
    style={{
      minHeight: IMAGE_CONTAINER_HEIGHT,
      width: "100%",
      backgroundImage: 'url("images/fleet-fuel-calc.png")',
      backgroundColor: colors.primary,

      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover"
    }}
  >
    {children}
  </Col>
);

const Header = ({ children }) => (
  <div
    style={{
      fontSize: "50px",
      fontWeight: 500,
      color: colors.white,
      marginBottom: "30px",
      textAlign: "center",
      maxWidth: "810px"
    }}
  >
    {children}
  </div>
);

const buttonStyles = {
  minWidth: "205px",
  height: "50px",
  backgroundColor: colors.secondary,
  color: colors.primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  margin: "10px",
  fontWeight: "bold",
  textAlign: "center",
  textTransform: "uppercase",
  borderRadius: 0,
  border: "none"
};

const Link = ({ href, onClick, children, style = {} }) => (
  <BaseLink
    to={href}
    onClick={onClick}
    style={{
      ...buttonStyles,
      ...style
    }}
  >
    {children}
  </BaseLink>
);

const Bullet = ({ title, children, style }) => (
  <div style={{ padding: "50px", ...style }}>
    <div
      style={{
        color: colors.darkGrey,
        fontSize: "28px",
        fontWeight: "bold",
        marginBottom: "40px"
      }}
    >
      {title}
    </div>
    <div
      style={{
        color: colors.darkGrey,
        fontSize: "18px"
      }}
    >
      {children}
    </div>
  </div>
);

const Home = () => {
  const [modalOpen, toggle] = useToggle(false);
  const { setInput } = useContext(InputContext);

  return (
    <>
      <Row>
        <ImageContainer>
          <TopNav active="home" />
          <Row style={{ minHeight: IMAGE_CONTAINER_HEIGHT }}>
            <Col valign style={{ flexDirection: "column" }}>
              <Header>
                Switch your fleet to electric and you could save big on fueling
              </Header>
              <Row
                style={{ width: "100%", justifyContent: "center" }}
                className="text-center"
              >
                <Col valign xs="12" sm="2">
                  <Link
                    href="/calculator"
                    onClick={() =>
                      setInput({ vehicleType: inputDefaults.vehicleType })
                    }
                  >
                    Vehicle Calculator
                  </Link>
                </Col>
                <Col valign xs="12" sm="2">
                  <Link
                    href="/calculator"
                    onClick={() => setInput({ vehicleType: `${KEYS.TRU}` })}
                  >
                    TRU Calculator
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </ImageContainer>
      </Row>
      <Row>
        <Col xs="12" sm>
          <Bullet
            title="Save Money"
            style={{ marginRight: "-15px", borderRight: "0.5px solid #D8D8D8" }}
          >
            Electric vehicles and hybrid Transport Refrigeration Units (TRUs)
            can reduce fuel costs significantly compared to conventional diesel
            or gasoline vehicle and TRUs. Fleet owners can also increase savings
            with Low Carbon Fuel Standard credits.
          </Bullet>
        </Col>
        <Col xs="12" sm>
          <Bullet
            title="Reduce Emissions"
            style={{ marginLeft: "-15px", borderLeft: "0.5px solid #D8D8D8" }}
          >
            With low-carbon electricity provided by SCE and the zero tailpipe
            emissions from electric vehicles and hybrid TRUs when plugged in,
            you can help reduce harmful greenhouse gas emissions and reduce
            local air pollution by converting your fleet to electric.
          </Bullet>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="text-left"
            style={{
              padding: "0 50px",
              borderTop: "1px solid #D8D8D8"
            }}
          >
            <p
              style={{
                color: colors.darkGrey,
                textAlign: "center",
                fontSize: "28px",
                fontWeight: "bold",
                marginTop: "40px",
                marginBottom: "40px"
              }}
            >
              Get Support
            </p>
            <p>
              SCE is here to support your fleet transition. The Vehicle
              Calculator compares the fueling costs of diesel to electric with
              our Electric Vehicle Rates. The TRU Calculator compares
              diesel-only Transport Refrigeration Units (TRUs) to hybrid TRUs
              (diesel with ability to plug in and run on electric when
              stationary).
            </p>
            <p>
              These tools are meant to provide an approximation of fuel savings
              from going electric for your selected vehicle type at a single
              location. Fuel savings depend on rate schedules, charging
              schedules, and charging level. If you wish to explore multiple
              locations or vehicle types, please contact us and we will be happy
              to help.
            </p>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "10px",
          marginBottom: "40px",
          justifyContent: "center"
        }}
      >
        <Col xs="12" sm="2" valign>
          <a
            href="https://sce.com/crt"
            target="_blank"
            rel="noopener noreferrer"
            style={buttonStyles}
          >
            Learn More
          </a>
        </Col>
        <Col xs="12" sm="2" valign>
          <button onClick={toggle} style={buttonStyles}>
            Contact Us
          </button>
        </Col>
      </Row>
      <ContactModal isOpen={modalOpen} toggle={toggle} />
      <Footer />
    </>
  );
};

export default Home;
