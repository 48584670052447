import React from "react";
import { Link } from "react-router-dom";
import { colors, Row, Col } from "../styles";

const MainLink = ({ href, children }) => (
  <Link
    to={href}
    style={{
      fontSize: "14px",
      color: colors.blueGrey,
      fontWeight: 500,
      whiteSpace: "nowrap",
      textTransform: "uppercase"
    }}
  >
    {children}
  </Link>
);

const SubLink = ({ href, children }) => (
  <a
    href={href}
    style={{
      fontSize: "14px",
      whiteSpace: "nowrap",
      fontWeight: 500,
      color: colors.lightGrey,
      float: 'right',
    }}
  >
    {children}
  </a>
);

const Footer = () => (
  <div className="d-print-none">
    <hr />
    <Row className="pt-3 pb-4">
      <Col xs="12" md="6" lg="4">
        <Row>
          <Col valign xs sm="2">
            <MainLink href="/">Home</MainLink>
          </Col>
          <Col valign xs sm="4">
            <MainLink href="/calculator/">Savings Calculator</MainLink>
          </Col>
          <Col valign xs sm="3">
            <MainLink href="/faq">FAQ</MainLink>
          </Col>
          <Col valign xs sm="3">
            <a
              href="https://www.sce.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "14px",
                color: colors.blueGrey,
                fontWeight: 500,
                whiteSpace: "nowrap",
                textTransform: "uppercase"
              }}
            >
              SCE Privacy Policy
            </a>
          </Col>
        </Row>
      </Col>
      <Col>
        <SubLink>
          &copy; 2022 Southern California Edison
        </SubLink>
      </Col>
    </Row>
  </div>
);

export default Footer;
