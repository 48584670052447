import A from "./ASSUMPTIONS";

// managed charging: spread total load over all hours from 9pm to 5am

const calcManagedCharging = (evseCount, maxChargeRate, dailyCharge) => {
  const dailyChargingHours = A.NINE_PM_TO_FIVE_AM_CHARGING_SCHEDULE.filter(
    (v) => v
  ).length;

  const managedChargePerHour =
    evseCount * Math.min(maxChargeRate, dailyCharge / dailyChargingHours);

  return A.NINE_PM_TO_FIVE_AM_CHARGING_SCHEDULE.map((hour) =>
    hour ? managedChargePerHour : 0
  );
};
export default calcManagedCharging;
