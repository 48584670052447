import moment from "moment";
import calcModel from "../calcs/calcModel";
import publicKey from "../data/publicKey";
import * as openpgp from "openpgp";
import titleizeCamelCase from "../utils/titleizeCamelCase";

const crypto = require('crypto');

const objToCSV = obj =>
  Object.keys(obj).reduce((csv, key) => {
    const newLine = `"${titleizeCamelCase(key)}","${obj[key]}"`;
    return `${csv}${newLine}\n`;
  }, "");

export default async function sendEmail(data) {
  const {
    setInput,
    firstName,
    lastName,
    email,
    phone,
    company,
    ...inputs
  } = data;
  const contactInfo = { firstName, lastName, email, phone, company };
  const { lifetime, avg, selectedRateName } = calcModel(inputs);
  const outputs = {
    lifetimeFuelSavings: lifetime.fuelSavings,
    lifetimeLcfsCredits: lifetime.lcfs,
    evTariff: selectedRateName,
    mpge: avg.mpge,
    avgAnnualDieselCost: avg.dieselCost,
    avgAnnualElectricCost: avg.electricityCost,
    avgAnnualLcfsCredits: avg.lcfs
  };

  const csv = `
    "SCE eMobility Calculator Lead",
    ,
    "Date", "${moment().format("YYYY-MM-DD")}",
    "Time", "${moment().format("h:mm:ss a")}",
    ,
    "Customer Information",
    ${objToCSV(contactInfo)}
    ,
    "Inputs",
    ${objToCSV(inputs)}
    ,
    "Outputs",
    ${objToCSV(outputs)}
  `;

  const url = new URL(
    process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_JOIN_NETWORK_PROD_BACKEND_HOST : process.env.REACT_APP_JOIN_NETWORK_DEV_BACKEND_HOST
  );
  const { data: encryptedData } = await openpgp.encrypt({
    message: openpgp.message.fromText(csv),
    publicKeys: (await openpgp.key.readArmored(publicKey)).keys
  });

  const payload = Buffer.from(encryptedData).toString('base64');

  const sendEmail = {
    service: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_JOIN_NETWORK_PROD_SERVICE_ID : process.env.REACT_APP_JOIN_NETWORK_DEV_SERVICE_ID,
    payload: [ payload ],
    checksum: "-Sha1-" + crypto.createHash("sha1").update(payload, "utf-8").digest("hex")
  }

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"        
    },
    body: JSON.stringify(sendEmail)
  }).catch(err => {
      console.log(err.response.data);
  });
}
