import TOU_EV_7 from "./rates/TOU_EV_7";
import TOU_EV_8 from "./rates/TOU_EV_8";
import TOU_EV_9 from "./rates/TOU_EV_9";

// First year of the element arrays
const FIRST_YEAR = 2024;

const lookupElectricRateElements = (rateName, year) => {
  let summerOnPeak,
    summerMidPeak,
    summerOffPeak,
    winterMidPeak,
    winterOffPeak,
    winterSuperoffPeak,
    customerChargePerDay,
    customerChargePerMonth,
    demandCharge,
    threePhaseServiceCharge;

  switch (rateName) {
    case "TOU-EV-7-E":
      summerOnPeak = TOU_EV_7[year - FIRST_YEAR].energy_summer_on;
      summerMidPeak = TOU_EV_7[year - FIRST_YEAR].energy_summer_mid;
      summerOffPeak = TOU_EV_7[year - FIRST_YEAR].energy_summer_off;
      winterMidPeak = TOU_EV_7[year - FIRST_YEAR].energy_winter_mid;
      winterOffPeak = TOU_EV_7[year - FIRST_YEAR].energy_winter_off;
      winterSuperoffPeak = TOU_EV_7[year - FIRST_YEAR].energy_winter_superoff;
      customerChargePerDay = TOU_EV_7[year - FIRST_YEAR].fixed_perday;
      customerChargePerMonth = TOU_EV_7[year - FIRST_YEAR].fixed_permonth;
      demandCharge = TOU_EV_7[year - FIRST_YEAR].demand_charge;
      threePhaseServiceCharge = TOU_EV_7[year - FIRST_YEAR].energy_threephaseserivce;
      break;
    case "TOU-EV-8":
      summerOnPeak = TOU_EV_8[year - FIRST_YEAR].energy_summer_on;
      summerMidPeak = TOU_EV_8[year - FIRST_YEAR].energy_summer_mid;
      summerOffPeak = TOU_EV_8[year - FIRST_YEAR].energy_summer_off;
      winterMidPeak = TOU_EV_8[year - FIRST_YEAR].energy_winter_mid;
      winterOffPeak = TOU_EV_8[year - FIRST_YEAR].energy_winter_off;
      winterSuperoffPeak = TOU_EV_8[year - FIRST_YEAR].energy_winter_superoff;
      customerChargePerDay = TOU_EV_8[year - FIRST_YEAR].fixed_perday;
      customerChargePerMonth = TOU_EV_8[year - FIRST_YEAR].fixed_permonth;
      demandCharge = TOU_EV_8[year - FIRST_YEAR].demand_charge;
      threePhaseServiceCharge = TOU_EV_8[year - FIRST_YEAR].energy_threephaseserivce;
      break;
    case "TOU-EV-9":
      summerOnPeak = TOU_EV_9[year - FIRST_YEAR].energy_summer_on;
      summerMidPeak = TOU_EV_9[year - FIRST_YEAR].energy_summer_mid;
      summerOffPeak = TOU_EV_9[year - FIRST_YEAR].energy_summer_off;
      winterMidPeak = TOU_EV_9[year - FIRST_YEAR].energy_winter_mid;
      winterOffPeak = TOU_EV_9[year - FIRST_YEAR].energy_winter_off;
      winterSuperoffPeak = TOU_EV_9[year - FIRST_YEAR].energy_winter_superoff;
      customerChargePerDay = TOU_EV_9[year - FIRST_YEAR].fixed_perday;
      customerChargePerMonth = TOU_EV_9[year - FIRST_YEAR].fixed_permonth;
      demandCharge = TOU_EV_9[year - FIRST_YEAR].demand_charge;
      threePhaseServiceCharge = TOU_EV_9[year - FIRST_YEAR].energy_threephaseserivce;
      break;
    default:
      break;
  }

  return {
    summerOnPeak,
    summerMidPeak,
    summerOffPeak,
    winterMidPeak,
    winterOffPeak,
    winterSuperoffPeak,
    customerChargePerDay,
    customerChargePerMonth,
    demandCharge,
    threePhaseServiceCharge,
    taxes: 0.0003,
  };
};

const getElectricRateElements = (rateName, year) => {
  const lookupYear = Math.min(year, FIRST_YEAR + TOU_EV_7.length - 1);
  return lookupElectricRateElements(rateName, lookupYear);
};

export default getElectricRateElements;
