import React from "react";
import BaseGraph from "./BaseGraph";
import { colors } from "../styles";
import { FormatAsDollars, FormatAsThousandsOfDollars } from "../utils/Format";
import A from "../calcs/ASSUMPTIONS";

const ElectricityCosts = ({ electricityCosts }) => {
  const data = {
    labels: electricityCosts.map((_, idx) => A.GRAPH_YR_LABELS[idx]),
    datasets: [
      {
        label: "Fixed",
        data: electricityCosts.map(costs => costs.fixed),
        backgroundColor: colors.chart.grey
      },
      {
        label: "Demand",
        data: electricityCosts.map(costs => costs.demand),
        backgroundColor: colors.chart.lightBlue
      },
      {
        label: "Energy",
        data: electricityCosts.map(costs => costs.energy),
        backgroundColor: colors.chart.blue
      }
    ]
  };

  return (
    <BaseGraph
      title="Electricity Costs"
      tooltip="This graph approximates annual electricity costs for your fleet. You can read more about Fixed, Demand, and Energy costs in the FAQ."
      data={data}
      yFormatCallback={FormatAsThousandsOfDollars}
      tooltipCallback={FormatAsDollars}
      stacked
    />
  );
};

export default ElectricityCosts;
