import { useLocation, useHistory } from "react-router-dom";

function useQueryString() {
  const currentQueryString = new URLSearchParams(useLocation().search);
  const queryParams = [...currentQueryString.entries()].reduce(
    (qs, [key, value]) => {
      if (key === 'chargingSchedule') {
        value = value.split(',').map(x => parseInt(x));
      }

      qs[key] = value;
      return qs;
    },
    {}
  );
  const history = useHistory();

  const setQueryString = params => {
    const newParams = new URLSearchParams(
      Object.assign({}, currentQueryString, params)
    );
    history.replace(`/calculator?${newParams}`);
  };

  return [queryParams, setQueryString];
}

export default useQueryString;
