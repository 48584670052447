import sum from "lodash/sum";

const PEAK = "on-peak";
const MID = "mid-peak";
const OFF = "off-peak";
const SUPEROFF = "super-off-peak";

const COUNT_SUMMER_MONTHS = 4;
const COUNT_WINTER_MONTHS = 8;

const SUMMER_WEEKDAY_TOU = [
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  PEAK,
  PEAK,
  PEAK,
  PEAK,
  PEAK,
  OFF,
  OFF,
  OFF,
];
const SUMMER_WEEKEND_TOU = [
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  MID,
  MID,
  MID,
  MID,
  MID,
  OFF,
  OFF,
  OFF,
];

const WINTER_WEEKDAY_TOU = [
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  MID,
  MID,
  MID,
  MID,
  MID,
  OFF,
  OFF,
  OFF,
];

const WINTER_WEEKEND_TOU = [
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  OFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  SUPEROFF,
  MID,
  MID,
  MID,
  MID,
  MID,
  OFF,
  OFF,
  OFF,
];

const getAnnualUsageByPeriod = (
  usage,
  daysPerWeek,
  monthsPerYear,
  TOU_ARRAY,
  TOU_KEY
) =>
  52 *
  daysPerWeek *
  (monthsPerYear / 12) *
  // if TOU_KEY is TOU_ARRAY[i], sum += usage[i]
  usage.reduce(
    (sum, el, idx) => (sum += TOU_ARRAY[idx] === TOU_KEY ? el : 0),
    0
  );

const calcBillingDeterminants = (usage, workdaysPerWeek) => {
  // Model assumes that 5 days or fewer occur M-F. More than 5 days inclues weekend day(s)
  const countWeekdays = workdaysPerWeek > 5 ? 5 : workdaysPerWeek;
  const countWeekends = workdaysPerWeek > 5 ? workdaysPerWeek - 5 : 0;

  const summerOnPeak = getAnnualUsageByPeriod(
    usage,
    countWeekdays,
    COUNT_SUMMER_MONTHS,
    SUMMER_WEEKDAY_TOU,
    PEAK
  );

  const summerMidPeak = getAnnualUsageByPeriod(
    usage,
    countWeekends,
    COUNT_SUMMER_MONTHS,
    SUMMER_WEEKEND_TOU,
    MID
  );

  const summerOffPeak = sum([
    getAnnualUsageByPeriod(
      usage,
      countWeekdays,
      COUNT_SUMMER_MONTHS,
      SUMMER_WEEKDAY_TOU,
      OFF
    ),
    getAnnualUsageByPeriod(
      usage,
      countWeekends,
      COUNT_SUMMER_MONTHS,
      SUMMER_WEEKDAY_TOU,
      OFF
    ),
  ]);

  const winterMidPeak = sum([
    getAnnualUsageByPeriod(
      usage,
      countWeekdays,
      COUNT_WINTER_MONTHS,
      WINTER_WEEKDAY_TOU,
      MID
    ),
    getAnnualUsageByPeriod(
      usage,
      countWeekends,
      COUNT_WINTER_MONTHS,
      WINTER_WEEKEND_TOU,
      MID
    ),
  ]);

  const winterOffPeak = sum([
    getAnnualUsageByPeriod(
      usage,
      countWeekdays,
      COUNT_WINTER_MONTHS,
      WINTER_WEEKDAY_TOU,
      OFF
    ),
    getAnnualUsageByPeriod(
      usage,
      countWeekends,
      COUNT_WINTER_MONTHS,
      WINTER_WEEKEND_TOU,
      OFF
    ),
  ]);

  const winterSuperoffPeak = sum([
    getAnnualUsageByPeriod(
      usage,
      countWeekdays,
      COUNT_WINTER_MONTHS,
      WINTER_WEEKDAY_TOU,
      SUPEROFF
    ),
    getAnnualUsageByPeriod(
      usage,
      countWeekends,
      COUNT_WINTER_MONTHS,
      WINTER_WEEKDAY_TOU,
      SUPEROFF
    ),
  ]);
  const customerChargePerDay = 365;

  const customerChargePerMonth = 12;

  const demandCharge = 12 * Math.max(...usage);

  const totalUsage =
    summerOnPeak +
    summerMidPeak +
    summerOffPeak +
    winterMidPeak +
    winterOffPeak +
    winterSuperoffPeak;

  return {
    summerOnPeak,
    summerMidPeak,
    summerOffPeak,
    winterMidPeak,
    winterOffPeak,
    winterSuperoffPeak,
    customerChargePerDay,
    customerChargePerMonth,
    demandCharge,
    totalUsage,
  };
};

export default calcBillingDeterminants;
