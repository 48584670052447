import React from "react";
import { Link as BaseLink } from "react-router-dom";
import { colors } from "../styles";

const Link = ({ active, children, href }) => {
  const styles = {
    fontSize: "16px",
    color: active ? colors.secondary : colors.white,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    fontWeight: "bold"
  };

  return (
    <BaseLink to={href} style={styles}>
      {children}
    </BaseLink>
  );
};

export default Link;
