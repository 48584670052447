import KEYS from "../calcs/KEYS";
import ASSUMPTIONS from "../calcs/ASSUMPTIONS";

export default {
  // Main Inputs
  vehicleType: "Class 3: Walk In",
  vehicleCount: 3,
  milesPerVehiclePerDay: 50,
  workdaysPerWeek: 5,
  vehicleLifetime: 10,

  // Other Configurations
  evseType: KEYS.RECOMMEND,
  vehicleToChargeportRatio: 1,
  chargingSchedule: ASSUMPTIONS.OVERNIGHT_HOURS, // [21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7]
  energySource: KEYS.GRID,
  dieselPrice: 3.9,

  // UI-based inputs
  includeLcfs: true,

  // TRU
  truDieselGalPerDay: 10,
  truOperatingHrsPerDay: 12,
  truPowerDrawInKw: 17.5,

  // Custom vehicle
  customConventionalVehicleType: "Class 3: Walk In",
  customConventionalVehicleMilesPerGal: 4.5,
  customElectricVehicleType: "Class 3: Walk In",
};
