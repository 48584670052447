const INFLATION_RATE = 0.03;

const CO2_PER_GAL_DIESEL = 22.38;
const POUNDS_PER_SHORT_TON = 2000;
const GRAMS_PER_SHORT_TON = 907185;
const MJ_PER_MWH = 3600;
const MJ_PER_KWH = MJ_PER_MWH / 1000;

const DEF_DOSING_RATE = 0.03;
const DEF_COST_PER_GAL = 2.8;

const LCFS_PRICE_IN_DOLLARS_PER_CREDIT = 62; // Source: https://ww2.arb.ca.gov/resources/documents/monthly-lcfs-credit-transfer-activity-reports
const GRID_AVERAGE_ELECTRICITY_CARBON_INTENSITY_IN_gCO2e_PER_MJ = 81.49;
const LCFS_EER_FOR_DIESEL_TO_EV = 5;
const LCFS_EER_FOR_DIESEL_TO_TRU = 3.4;

const BTU_PER_GAL_DIESEL = 137381; // Source: https://www.eia.gov/energyexplained/units-and-calculators/energy-conversion-calculators.php
const BTU_PER_KWH = 3412;

const TRU_CHARGE_RATE_IN_KW = 480 * 30 * Math.sqrt(3) * (1 / 1000);

const CARBON_INTENSITY_IN_gCO2_PER_MJ = [
  76.99,
  75.99,
  75.5275,
  75.78,
  75.955,
  79.3625,
  93.315,
  91.09,
  60.6,
  55.62,
  52.315,
  62.905,
  64.73,
  66.915,
  69.32,
  73.67,
  75.69,
  97.505,
  121.135,
  127.405,
  119.235,
  100.6775,
  84.78,
  78.4525,
];

const LCFS_DIESEL_SUBSTITUTE_COMPLIANCE_SCHEDULE = {
  "2020": 92.91625,
  "2021": 91.660625,
  "2022": 90.405,
  "2023": 89.149375,
  "2024": 87.89375,
  "2025": 86.638125,
  "2026": 85.3825,
  "2027": 84.126875,
  "2028": 82.87125,
  "2029": 81.615625,
  "2030": 80.36,
};

const CARBON_INTENSITY_IN_ST_CO2_PER_MWH = CARBON_INTENSITY_IN_gCO2_PER_MJ.map(
  (intensity) => intensity * MJ_PER_MWH * (1 / GRAMS_PER_SHORT_TON)
);

const OVERNIGHT_HOURS = [21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
const MIDDAY_HOURS = [8, 9, 10, 11, 12, 13, 14, 15];

const NINE_PM_TO_FIVE_AM_CHARGING_SCHEDULE = [
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  true,
  true,
];

const UNMANAGED_CHARGING_HOUR_START = 17;
const UNMANAGED_CHARGING_HOUR_END = 5;

const GRAPH_YR_LABELS = [
  "YR 1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];

export default {
  INFLATION_RATE,
  CO2_PER_GAL_DIESEL,
  POUNDS_PER_SHORT_TON,
  GRAMS_PER_SHORT_TON,
  MJ_PER_MWH,
  MJ_PER_KWH,
  DEF_DOSING_RATE,
  DEF_COST_PER_GAL,
  LCFS_PRICE_IN_DOLLARS_PER_CREDIT,
  GRID_AVERAGE_ELECTRICITY_CARBON_INTENSITY_IN_gCO2e_PER_MJ,
  LCFS_EER_FOR_DIESEL_TO_EV,
  LCFS_EER_FOR_DIESEL_TO_TRU,
  BTU_PER_GAL_DIESEL,
  BTU_PER_KWH,
  TRU_CHARGE_RATE_IN_KW,
  CARBON_INTENSITY_IN_gCO2_PER_MJ,
  LCFS_DIESEL_SUBSTITUTE_COMPLIANCE_SCHEDULE,
  CARBON_INTENSITY_IN_ST_CO2_PER_MWH,
  OVERNIGHT_HOURS,
  MIDDAY_HOURS,
  NINE_PM_TO_FIVE_AM_CHARGING_SCHEDULE,
  UNMANAGED_CHARGING_HOUR_START,
  UNMANAGED_CHARGING_HOUR_END,
  GRAPH_YR_LABELS,
};
