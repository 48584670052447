import React from "react";
import ASSUMPTIONS from "../calcs/ASSUMPTIONS";
import { CheckButton } from "../inputs";
import { colors, Row, Col, Tooltip } from "../styles";

const Lcfs = ({ value, onChange }) => {
  return (
    <div>
      <label style={{ color: colors.lightGrey }}>
        Electric Vehicles/Hybrid TRUs can generate revenue from Low Carbon Fuel
        Standard (LCFS) credits.{" "}
        <Tooltip>
          The calculation below also assumes a flat LCFS credit price of $
          {ASSUMPTIONS.LCFS_PRICE_IN_DOLLARS_PER_CREDIT}. This value is not
          guaranteed going forward and can change at any time.
        </Tooltip>
      </label>

      <Row style={{ marginTop: "15px" }}>
        <Col sm="6">
          <label style={{ fontWeight: "bold", color: colors.blueGrey }}>
            Include LCFS credits
          </label>
        </Col>
        <Col style={{ padding: "7px" }}>
          <CheckButton onClick={() => onChange(true)} checked={value}>
            Yes
          </CheckButton>
        </Col>
        <Col style={{ padding: "7px" }}>
          <CheckButton onClick={() => onChange(false)} checked={!value}>
            No
          </CheckButton>
        </Col>
      </Row>
    </div>
  );
};

export default Lcfs;
