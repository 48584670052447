import React from "react";
import ASSUMPTIONS from "../calcs/ASSUMPTIONS";
import { colors, Tooltip } from "../styles";
import { FormatAsDollars, FormatAsInt } from "../utils/Format";

export const Section = ({ children }) => (
  <div
    style={{
      marginBottom: "30px",
      marginTop: "30px"
    }}
  >
    {children}
  </div>
);

export const Label = ({ children }) => (
  <div
    style={{
      textTransform: "uppercase",
      color: colors.blueGrey,
      fontWeight: "bold",
      fontSize: "12px"
    }}
  >
    {children}
  </div>
);

export const Value = ({ children }) => (
  <div
    style={{
      fontSize: "30px",
      color: colors.blueGrey
    }}
  >
    {typeof children !== 'object' && children}
  </div>
);

const Divider = () => <hr />;

const Button = ({ onClick, children }) => (
  <button
    className="btn"
    onClick={onClick}
    style={{
      width: "100%",
      height: "50px",
      border: "1px solid rgba(0,0,0,.1)",
      backgroundColor: colors.white,
      color: colors.primary,
      margin: "20px 0",
      padding: "20px",
      textTransform: "uppercase",
      fontSize: "16px",
      display: "flex",
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 0
    }}
  >
    {children}
  </button>
);

const KeyOutputs = ({ outputs }) => {
  const hasErrors = outputs.modelErrors.hasErrors;

  return (
    <div className="mt-5">
      <Section>
        <Label>Lifetime Fuel Savings</Label>
        <Value>
          {hasErrors ? "---" : FormatAsDollars(outputs.lifetime.fuelSavings)}
        </Value>
      </Section>
      <Section>
        <Label>
          Lifetime LCFS Credits &nbsp;
          <Tooltip>
            Potential values of Low Carbon Fuel Standard credit, based on $
            {ASSUMPTIONS.LCFS_PRICE_IN_DOLLARS_PER_CREDIT}/credit.
          </Tooltip>
        </Label>
        <Value>
          {hasErrors ? "---" : FormatAsDollars(outputs.lifetime.lcfs)}
        </Value>
      </Section>

      <Divider />

      <Section>
        <Label>Rate Plan</Label>
        <Value>{hasErrors ? "---" : outputs.selectedRateName}</Value>
      </Section>
      <Section>
        <Label>
          Annual GHG Emissions Saved &nbsp;
          <Tooltip>Net reduction of annual greenhouse gas emissions</Tooltip>
        </Label>
        <Value>
          {hasErrors
            ? "---"
            : FormatAsInt(outputs.avg.ghgEmissionsSavedInTons).toString() +
              " tons"}
        </Value>
      </Section>
      {!outputs.isTru && (
        <Section>
          <Label>
            MPGe &nbsp;
            <Tooltip>
              Miles per Gallon equivalent to compare to conventional vehicles
            </Tooltip>
          </Label>
          <Value>{hasErrors ? "---" : FormatAsInt(outputs.avg.mpge)}</Value>
        </Section>
      )}

      <Divider />

      <Section>
        <Label>Avg Annual Diesel Cost</Label>
        <Value>
          {hasErrors ? "---" : FormatAsDollars(outputs.avg.dieselCost)}
        </Value>
      </Section>
      <Section>
        <Label>
          {outputs.isTru
            ? "Avg Annual Hybrid Cost"
            : "Avg Annual Electric Cost"}
        </Label>
        <Value>
          {hasErrors ? "---" : FormatAsDollars(outputs.avg.electricityCost)}
        </Value>
      </Section>
      <Section>
        <Label>
          Avg Annual LCFS Credits &nbsp;
          <Tooltip>
            Find out more about Low Cabon Fuel Standard (LCFS) credits in our
            FAQ
          </Tooltip>
        </Label>
        <Value>{hasErrors ? "---" : FormatAsDollars(outputs.avg.lcfs)}</Value>
      </Section>

      <Divider />

      <Button onClick={() => window.print()}>Print Report</Button>
    </div>
  );
};

export default KeyOutputs;
