import React from "react";

const Toggler = ({ height = "auto", width = "auto" }) => (
  <svg
    style={{ height, width }}
    className="bi bi-list"
    alt="Toggle Icon"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M4.5 13.5A.5.5 0 015 13h10a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-4A.5.5 0 015 9h10a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-4A.5.5 0 015 5h10a.5.5 0 010 1H5a.5.5 0 01-.5-.5z"
      clipRule="evenodd"
    />
  </svg>
);

export default Toggler;
