import React from "react";
import { colors } from "../styles";

const RequiredWarning = () => (
  <>
  * <small>(Required)</small>
  </>
)

const Text = ({ label, value = "", onChange, error = false }) => (
  <label style={{ color: error ? 'red' : colors.grey, margin: "15px 0", width: "100%" }}>
    {label}
    {error && <RequiredWarning />}
    <input
      type="text"
      style={{ marginTop: "10px", borderColor: error ? 'red' : 'inherit' }}
      value={value}
      className="form-control"
      onChange={e => onChange(e.currentTarget.value)}
    />
  </label>
);

export default Text;
