import React, { useState } from "react";
import { useDebounceEffect } from "@bellawatt/react-hooks";
import Honeybadger from 'honeybadger-js';
import { useQueryString, useLocalStorage, useGoogleAnalytics } from "./hooks";
import isEmpty from "lodash/isEmpty";
import InputContext from "./context";
import inputDefaults from "./context/defaults";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from "reactstrap";
import Calculator from "./pages/Calculator";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import "./App.css";
import "./print.css";

const App = () => {
  useGoogleAnalytics('G-Q9X1BV2B04');
  const [urlInputs, updateQueryString] = useQueryString();
  const [localInputs, setLocalInputs] = useLocalStorage("inputs");
  const hasUrlInputs = !isEmpty(urlInputs);

  const [inputs, setInputs] = useState({
    ...inputDefaults,
    ...(hasUrlInputs ? urlInputs : localInputs),
  });

  useDebounceEffect(
    () => {
      updateQueryString(inputs);
      setLocalInputs(inputs);
      Honeybadger.setContext(inputs);
    },
    500,
    [inputs]
  );
  const setInput = obj => setInputs(current => ({ ...current, ...obj }));

  return (
    <InputContext.Provider value={{ ...inputs, setInput }}>
      <Switch>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/calculator">
          <Calculator isFirstVisit={!hasUrlInputs && !localInputs} />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </InputContext.Provider>
  );
};

const RouterContainer = () => (
  <Container style={{ maxWidth: "1440px" }}>
    <Router>
      <App />
    </Router>
  </Container>
);

export default RouterContainer;
