import React from "react";
import { useToggle } from "@bellawatt/react-hooks";
import { colors } from "../styles";
import {
  ElectricityVsDieselFuelCosts,
  FuelSavingsPerMile,
  ElectricityCosts,
  ManagedVsUnmanagedCharging
} from "../graphs";
import Caret from "../styles/Caret";

const AdvancedLine = ({ direction, onClick }) => (
  <div
    style={{
      width: "100%",
      textAlign: "center",
      borderBottom: `1px solid #e4e4e4`,
      lineHeight: "0.1em",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px 0"
    }}
  >
    <button
      onClick={onClick}
      style={{
        background: colors.background,
        border: "none",
        padding: "0 10px",
        textTransform: "uppercase",
        position: "absolute",
        fontWeight: "bold"
      }}
    >
      Advanced Graphs
      <Caret direction={direction} />
    </button>
  </div>
);

const AdvancedGraphs = ({ children }) => {
  const [expanded, toggle] = useToggle(false);

  return (
    <>
      <AdvancedLine onClick={toggle} direction={expanded ? "up" : "down"} />
      {expanded && children}
    </>
  );
};

const Graphs = ({ outputs }) => (
  <>
    <ElectricityVsDieselFuelCosts {...outputs.annual} isTru={outputs.isTru} />
    {outputs.isTru && <ElectricityCosts {...outputs.annual} />}
    {!outputs.isTru && (
      <>
        <FuelSavingsPerMile {...outputs.annual} />
        <AdvancedGraphs>
          <ElectricityCosts {...outputs.annual} />
          <ManagedVsUnmanagedCharging {...outputs.annual} />
        </AdvancedGraphs>
      </>
    )}
  </>
);

export default Graphs;
