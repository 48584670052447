import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function useGoogleAnalytics(analyticsId) {
  ReactGA.initialize(analyticsId);

  const location = useLocation();

  // The Google Analytics code loaded by
  // react-ga4 should send pageviews
  // automatically. But in this project,
  // that wasn't happening.
  // So here we recreate that behavior.
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
}
