export default `
-----BEGIN PGP PUBLIC KEY BLOCK-----

mQENBGZeAiwBCADlOc/R1jkk0HKe288PJr0N61cxoQXaxti6csIC5RfyHeRR4YtF
GJZ+AsAjcLUl3P4VZZ0LoQevsw7UpIP+FOoA51ab2K3vSwF4fKxDmNzvcGBK7gjb
KB9oSzdanvMmks1swAMoeXaa02SDGOWig+bwGPibt6zqjqct0yA5f7O9By5GtS83
QT8BwPlyprEZhmuqgXeTUcMGEfsRO+0B44Rx32y9+rzI84ZoPr6h23JCh0OkTxI1
Ou4cO1oJoKH74K8d6DjOR1eJmG08QXuW0H0aR+kU9kOW1IkJVPjRo2FZf0mJ100h
/JsOAoh/q5nrI/2zK68nhAWsK6G6ABhjvex9ABEBAAG0LUphc29uIEdyb3ZlcyAo
RkZDIEtleSkgPGphc29uLmdyb3Zlc0BzY2UuY29tPokBOQQTAQIAIwUCZl4CLAIb
DwcLCQgHAwIBBhUIAgkKCwQWAgMBAh4BAheAAAoJEGlOZDz//8lDB6AIAITWRGiq
GiN6zPc6V5HFEaxPQWCIQDS2b8dwALHtME8NGwa/iQi/uOlItrjtBv5lCM/qa+J0
pMrfYZWUVQya2UPa77rv4blctDmS+PPqg8yJnYauWOeMd9i0mKDr/D+xvqXKIP+z
fUekW368hkTsdwiTAT1RlSjcXj9DgOvH+PahpYGL24mmpT5DQtoHHHbHZ+d4gCvC
w+dTFQjHCPAO0J10UpAvGVSxfJuCiTaAVpDFt4bDHXqbl2Ns2dgo3Nb6Z1Wsqsz1
LWJkKTxtcNyQaIJsdnzFSO4Wx1WaxrLIxD+yhIkay427tfc7MLY3yqei20x3DU7t
30w/VcKGKDQU0Aw=
=7gDN

-----END PGP PUBLIC KEY BLOCK-----
`;
