import React, { useState, useContext } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { Row, Col, colors } from "../styles";
import { Slider, Select } from "../inputs";
import InputContext from "../context";
import VEHICLES from "../data/VEHICLES";
import KEYS from "../calcs/KEYS";

const styles = {
  backgroundColor: colors.primary,
  minHeight: "140px",
  paddingRight: "20px"
};

const PrimaryInputs = () => {
  const {
    setInput,
    vehicleType,
    vehicleCount,
    milesPerVehiclePerDay,
    workdaysPerWeek,
    vehicleLifetime
  } = useContext(InputContext);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const canShowVehiclePopover = vehicleType !== KEYS.CUSTOM;

  const renderMoreInfo = canShowVehiclePopover ? (
    vehicleType === KEYS.TRU ? (
      <>
        <img
          className="img-fluid"
          src={"/images/tru.png"}
          alt={"An image of a " + KEYS.TRU}
        />
      </>
    ) : (
      <>
        <p>
          Electric Range:
          <span className="float-right">
            {VEHICLES[vehicleType].electricRangeInMiles} miles
          </span>
          <br />
          Electric Capacity:
          <span className="float-right">
            {VEHICLES[vehicleType].capacityInKwh} kWh
          </span>
          <br />
          Max Charge Rate:
          <span className="float-right">
            {VEHICLES[vehicleType].maxChargeRateInKw} kW
          </span>
          <br />
          Conventional Diesel:
          <span className="float-right">
            {VEHICLES[vehicleType].conventionalDieselMpg} mpg
          </span>
        </p>
        <img
          className="img-fluid"
          src={"/images/" + VEHICLES[vehicleType].imgSource}
          alt={"An image of a " + VEHICLES[vehicleType].vehicleType}
        />
      </>
    )
  ) : null;

  return (
    <>
      <Row style={styles} className="header-filters">
        <Col xs="12" sm>
          <Select
            label="Vehicle Type"
            tooltip="Cannot find your vehicle type? Please contact us to help you."
            color="#FFF"
            options={[
              ...Object.keys(VEHICLES).map(value => ({
                key: value,
                option: value
              })),
              { option: KEYS.TRU, key: KEYS.TRU },
              { option: KEYS.CUSTOM, key: KEYS.CUSTOM }
            ]}
            value={vehicleType}
            onChange={vehicleType => {
              setPopoverOpen(false);
              setInput({ vehicleType });
            }}
          />
          <button
            className="btn btn-sm btn-default"
            id="more_info_vehicle_popover"
            type="button"
            style={{ color: "white" }}
          >
            {canShowVehiclePopover && "More Info"}
          </button>

          <Popover
            delay={0}
            placement="bottom"
            isOpen={popoverOpen}
            target="more_info_vehicle_popover"
            toggle={togglePopover}
          >
            <PopoverBody>{canShowVehiclePopover && renderMoreInfo}</PopoverBody>
          </Popover>
        </Col>
        <Col xs="12" sm>
          <Slider
            label="# of Vehicles Adopted"
            color={colors.white}
            tooltip="How many vehicles are you looking to replace or adopt?"
            min={1}
            max={300}
            value={vehicleCount}
            onChange={vehicleCount => setInput({ vehicleCount })}
          />
        </Col>
        <Col xs="12" sm>
          <Slider
            label="Vehicle Lifetime"
            tooltip="For how many years do you want to view fuel savings?"
            color={colors.white}
            min={1}
            max={20}
            value={vehicleLifetime}
            onChange={vehicleLifetime => setInput({ vehicleLifetime })}
          />
        </Col>
        <Col xs="12" sm>
          <Slider
            label="Workdays (per week)"
            color={colors.white}
            min={1}
            tooltip="How many days per week are your vehicles used?"
            max={7}
            value={workdaysPerWeek}
            onChange={workdaysPerWeek => setInput({ workdaysPerWeek })}
          />
        </Col>
        {vehicleType !== KEYS.TRU && (
          <Col xs="12" sm>
            <Slider
              label="Avg. Daily Miles Per Vehicle"
              color={colors.white}
              min={25}
              max={300}
              step={25}
              value={milesPerVehiclePerDay}
              onChange={milesPerVehiclePerDay =>
                setInput({ milesPerVehiclePerDay })
              }
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default PrimaryInputs;
