import React from "react";
import { Col as BaseCol, Row as BaseRow } from "reactstrap";

export { Card } from "reactstrap";
export { default as Tooltip } from './Tooltip';
export { default as Modal } from "./Modal";

export const colors = {
  primary: "#052037",
  secondary: "#fed047",
  white: "#fff",
  blueGrey: "#4a5e6e",
  grey: "#052036",
  lightGrey: "#717171",
  darkGrey: "#333333",
  background: "#f8f8f8",

  chart: {
    yellow: "#fed047",
    darkBlue: "#052037",
    green: "#006A53",
    orange: "#ED7D31",
    lightBlue: "#007AFF",
    blue: "#013B7B",
    grey: "#C9C8C8"
  }
};

export const Row = ({ fullHeight = false, children, style, ...props }) => {
  const newStyle = Object.assign(
    fullHeight
      ? {
          height: "100%"
        }
      : {},
    style
  );

  return (
    <BaseRow {...props} style={newStyle}>
      {children}
    </BaseRow>
  );
};

export const Col = ({
  valign = false,
  hideMobile = false,
  onlyMobile = false,
  children,
  style,
  className = "",
  ...props
}) => {
  const newStyle = Object.assign(
    valign
      ? {
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }
      : {},
    style
  );

  const displayType = valign ? "flex" : "block";

  const newClass = `${className} ${
    hideMobile ? `d-none d-sm-${displayType}` : ""
  } ${onlyMobile ? `d-${displayType} d-sm-none` : ""}`;

  return (
    <BaseCol {...props} className={newClass} style={newStyle}>
      {children}
    </BaseCol>
  );
};
