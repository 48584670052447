import VEHICLES from "../data/VEHICLES";
import EVSE from "../data/EVSE";
import calcElectricityCosts from "./calcElectricityCosts";
import A from "./ASSUMPTIONS";
import KEYS from "./KEYS";
import sumProduct from "./sumProduct";
import moment from "moment";
import times from "lodash/times";
import sum from "lodash/sum";
import calcManagedCharging from "./calcManagedCharging";
import calcUnmanagedCharging from "./calcUnmanagedCharging";

// ***************************************************************************
// Assumptions
// ***************************************************************************

const calcModel = ({
  vehicleType,
  vehicleCount,
  milesPerVehiclePerDay,
  workdaysPerWeek,
  vehicleLifetime,
  evseType,
  vehicleToChargeportRatio,
  chargingSchedule,
  energySource,
  dieselPrice,
  includeLcfs,
  truDieselGalPerDay,
  truOperatingHrsPerDay,
  truPowerDrawInKw,
  customConventionalVehicleType,
  customElectricVehicleType,
  customConventionalVehicleMilesPerGal,
}) => {
  // ***************************************************************************
  // Lookups (Based on Inputs)
  // ***************************************************************************

  const isTru = vehicleType === KEYS.TRU;
  const isRecommendingEvse = evseType === KEYS.RECOMMEND;

  const conventionalVehicleType =
    vehicleType === KEYS.CUSTOM ? customConventionalVehicleType : vehicleType;
  const electricVehicleType =
    vehicleType === KEYS.CUSTOM ? customElectricVehicleType : vehicleType;

  const conventionalDieselMpg =
    conventionalVehicleType === KEYS.CUSTOM
      ? customConventionalVehicleMilesPerGal
      : isTru
      ? 0
      : VEHICLES[conventionalVehicleType].conventionalDieselMpg;
  const electricRangeInMiles = isTru
    ? 0
    : VEHICLES[electricVehicleType].electricRangeInMiles;
  const capacityInKwh = isTru ? 0 : VEHICLES[electricVehicleType].capacityInKwh;
  const vehicleMaxChargeRateInKw = isTru
    ? 0
    : VEHICLES[electricVehicleType].maxChargeRateInKw;

  const dailyChargingSchedule = times(24, (i) => chargingSchedule.includes(i));

  // ***************************************************************************
  // General
  // ***************************************************************************

  const firstYear = moment().year();
  const years = times(vehicleLifetime, (i) => firstYear + i);
  const dailyChargingHours = chargingSchedule.length;

  const vehicleMPe = electricRangeInMiles / capacityInKwh;

  const dwellTime = dailyChargingHours / vehicleToChargeportRatio;

  const minEvseChargeRateInKw =
    Math.max(electricRangeInMiles, milesPerVehiclePerDay) *
    (1 / vehicleMPe) *
    (1 / dwellTime);

  const recommendedEvseLookup = Object.keys(EVSE)
    .map((key) => EVSE[key])
    .sort((a, b) => a.chargeRatePerPortCutoff - b.chargeRatePerPortCutoff)
    .find(
      ({ chargeRatePerPortCutoff }) =>
        chargeRatePerPortCutoff > minEvseChargeRateInKw
    );

  const canRecommendEvse = isTru
    ? true
    : isRecommendingEvse
    ? recommendedEvseLookup !== undefined
    : true;

  const evseRecommended = canRecommendEvse
    ? recommendedEvseLookup
    : Object.keys(EVSE)
        .map((key) => EVSE[key])
        .sort((a, b) => a.chargeRatePerPortCutoff - b.chargeRatePerPortCutoff)
        .slice(-1)[0];

  const useRecommendedEvse = !isTru && isRecommendingEvse;
  const evseValue = isTru ? EVSE[Object.keys(EVSE)[0]] : EVSE[evseType];

  const chargingPorts = useRecommendedEvse
    ? evseRecommended.chargingPorts
    : evseValue.chargingPorts;
  const evseChargeRatePerPortInKw = useRecommendedEvse
    ? evseRecommended.chargeRatePerPortInKw
    : evseValue.chargeRatePerPortInKw;
  const evseChargingEfficiency = useRecommendedEvse
    ? evseRecommended.chargingEfficiency
    : evseValue.chargingEfficiency;
  const chargeRatePerPortCutoff = useRecommendedEvse
    ? evseRecommended.chargeRatePerPortCutoff
    : evseValue.chargeRatePerPortCutoff;

  const evseIsAdequatelySized =
    isTru || isRecommendingEvse
      ? true
      : minEvseChargeRateInKw <= chargeRatePerPortCutoff;

  const evseCount = evseIsAdequatelySized
    ? Math.ceil(
        vehicleCount * (1 / chargingPorts) * (1 / vehicleToChargeportRatio)
      )
    : 0;

  const vehicleCountByYear = years.map(() => vehicleCount);

  const annualFleetMiles = vehicleCountByYear.map(
    (vehicles) => vehicles * milesPerVehiclePerDay * workdaysPerWeek * 52
  );

  // Annual Charging
  const dailyChargePerVehicle = milesPerVehiclePerDay / vehicleMPe;

  const dailyChargeToFull =
    dailyChargePerVehicle *
    (100 / evseChargingEfficiency) *
    vehicleCount *
    (1 / evseCount);

  const chargeRateInKw = Math.min(
    vehicleMaxChargeRateInKw,
    dailyChargeToFull / dailyChargingHours
  );

  const dailyCharge = chargeRateInKw * dailyChargingHours;
  const canChargeToFull = isTru
    ? true
    : Math.round(dailyCharge * 10) === Math.round(dailyChargeToFull * 10);

  // user-specified charging: spread total load over all selected hours
  const userChargingKw = dailyChargingSchedule.map((hour) =>
    hour
      ? isTru
        ? vehicleCount * truPowerDrawInKw
        : (evseCount * dailyCharge) / dailyChargingHours
      : 0
  );

  const managedChargingKw = calcManagedCharging(
    evseCount,
    Math.min(evseChargeRatePerPortInKw, vehicleMaxChargeRateInKw),
    dailyChargeToFull
  );

  const unmanagedChargingKw = calcUnmanagedCharging(
    evseCount,
    Math.min(evseChargeRatePerPortInKw, vehicleMaxChargeRateInKw),
    dailyChargeToFull
  );

  const totalAnnualUsage = workdaysPerWeek * 52 * sum(userChargingKw);

  // Operating hours should always be greater than the total number of charging hours.
  const truOperatesLongerThanCharging = isTru
    ? truOperatingHrsPerDay >= dailyChargingHours
    : true;

  // ***************************************************************************
  // Electricity
  // ***************************************************************************

  const maxMonthlyEvMeterDemand = isTru
    ? A.TRU_CHARGE_RATE_IN_KW * vehicleCount
    : evseChargeRatePerPortInKw * evseCount;

  const selectedRateName =
    maxMonthlyEvMeterDemand <= 20
      ? "TOU-EV-7-E"
      : maxMonthlyEvMeterDemand > 500
      ? "TOU-EV-9"
      : "TOU-EV-8";

  const annualElectricityCosts = calcElectricityCosts(
    userChargingKw,
    selectedRateName,
    years,
    workdaysPerWeek,
    A.INFLATION_RATE
  );

  const managedElectricityCosts = !isTru
    ? calcElectricityCosts(
        managedChargingKw,
        selectedRateName,
        years,
        workdaysPerWeek,
        A.INFLATION_RATE
      )
    : null;

  const unmanagedElectricityCosts = !isTru
    ? calcElectricityCosts(
        unmanagedChargingKw,
        selectedRateName,
        years,
        workdaysPerWeek,
        A.INFLATION_RATE
      )
    : null;

  // ***************************************************************************
  // Conventional Fuel
  // ***************************************************************************

  const annualDieselAvoidedInGal = vehicleCountByYear.map((vehicles) =>
    isTru
      ? vehicles *
        (dailyChargingHours / truOperatingHrsPerDay) *
        truDieselGalPerDay *
        workdaysPerWeek *
        52
      : vehicles *
        milesPerVehiclePerDay *
        workdaysPerWeek *
        52 *
        (1 / conventionalDieselMpg)
  );

  const annualTruDieselInGal = vehicleCountByYear.map((vehicles) =>
    isTru ? vehicles * truDieselGalPerDay * workdaysPerWeek * 52 : 0
  );

  const annualDieselCostPerGal = years.map(
    (year, idx) => dieselPrice * Math.pow(1 + A.INFLATION_RATE, idx)
  );

  const annualDefCostPerGal = years.map(
    (year, idx) => A.DEF_COST_PER_GAL * Math.pow(1 + A.INFLATION_RATE, idx)
  );

  const annualDieselCosts = annualDieselCostPerGal.map((dollarsPerGal, idx) =>
    isTru
      ? dollarsPerGal * annualTruDieselInGal[idx]
      : annualDieselAvoidedInGal[idx] *
        (dollarsPerGal + A.DEF_DOSING_RATE * annualDefCostPerGal[idx])
  );

  // ***************************************************************************
  // Environmental
  // ***************************************************************************

  const netCo2EmissionsSavingsInShortTons = annualDieselAvoidedInGal.map(
    (diesel) =>
      diesel * A.CO2_PER_GAL_DIESEL * (1 / A.POUNDS_PER_SHORT_TON) -
      sumProduct(userChargingKw, A.CARBON_INTENSITY_IN_ST_CO2_PER_MWH) *
        workdaysPerWeek *
        52 *
        (1 / 1000)
  );

  const eer =
    vehicleType === KEYS.TRU
      ? A.LCFS_EER_FOR_DIESEL_TO_TRU
      : A.LCFS_EER_FOR_DIESEL_TO_EV;

  const carbonIntensity =
    energySource === KEYS.GRID
      ? A.GRID_AVERAGE_ELECTRICITY_CARBON_INTENSITY_IN_gCO2e_PER_MJ
      : 0;

  const annualLcfsCreditPrice = years.map((year) => {
    return year > 2030
      ? 0
      : (eer * A.LCFS_DIESEL_SUBSTITUTE_COMPLIANCE_SCHEDULE[year.toString()] -
          carbonIntensity) *
          A.LCFS_PRICE_IN_DOLLARS_PER_CREDIT *
          A.MJ_PER_KWH *
          (1 / 1000000);
  });

  const annualLcfsCredits = annualLcfsCreditPrice.map((price, i) =>
    includeLcfs
      ? price * totalAnnualUsage * Math.pow(1 + A.INFLATION_RATE, i)
      : 0
  );

  const lcfsPerMile = annualFleetMiles.map(
    (miles, i) => annualLcfsCredits[i] / miles
  );

  const fuelSavingsPerMile = annualFleetMiles.map(
    (miles, i) =>
      (annualDieselCosts[i] - annualElectricityCosts[i].total) / miles
  );

  // ***************************************************************************
  // Outputs
  // ***************************************************************************

  const electricCosts = annualElectricityCosts.map((electricityCosts, idx) => {
    const fixed = electricityCosts.fixed;
    const demand = electricityCosts.demand;
    const energy = electricityCosts.energy;

    // Warning!! With TRUs, the total electricity costs are not always equal to the sum of Fixed, Demand, and Energy components
    // Per calculation tab 3 row 214 :
    const dieselForElectricTru = isTru
      ? annualDieselCostPerGal[idx] *
        (annualTruDieselInGal[idx] - annualDieselAvoidedInGal[idx])
      : 0;

    return {
      fixed,
      demand,
      energy,
      dieselForElectricTru,
      total: fixed + demand + energy + dieselForElectricTru,
    };
  });

  const lifetimeElectricCosts = sum(electricCosts.map((costs) => costs.total));
  const lifetimeDieselCosts = sum(annualDieselCosts);
  const lifetimeFuelSavings = lifetimeDieselCosts - lifetimeElectricCosts;
  const lifetimeLcfs = sum(annualLcfsCredits);

  const annual = {
    dieselCosts: annualDieselCosts,
    lcfsCredits: annualLcfsCredits,
    fuelSavingsPerMile,
    lcfsPerMile,

    electricityCosts: electricCosts,
    managedElectricityCosts,
    unmanagedElectricityCosts,

    netCo2EmissionsSavingsInShortTons,
  };

  const lifetime = {
    netBenefit: lifetimeFuelSavings + lifetimeLcfs,
    fuelSavings: lifetimeFuelSavings,
    lcfs: lifetimeLcfs,
  };

  const avg = {
    electricityCost: lifetimeElectricCosts / vehicleLifetime,
    dieselCost: lifetimeDieselCosts / vehicleLifetime,
    ghgEmissionsSavedInTons:
      sum(netCo2EmissionsSavingsInShortTons) / vehicleLifetime,
    lcfs: lifetimeLcfs / vehicleLifetime,
    mpge: vehicleMPe * A.BTU_PER_GAL_DIESEL * (1 / A.BTU_PER_KWH),
  };

  const modelErrors = {
    hasErrors:
      !evseIsAdequatelySized ||
      !canRecommendEvse ||
      !truOperatesLongerThanCharging,
    evseIsAdequatelySized,
    canRecommendEvse,
    truOperatesLongerThanCharging,
  };

  return {
    isTru,
    canChargeToFull,
    selectedRateName,
    evseCount,
    evseRecommended,
    chargeRateInKw,
    dailyChargingHours,
    lifetime,
    avg,
    annual,
    modelErrors,
  };
};

export default calcModel;
