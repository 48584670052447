import getElectricRateElements from "../data/getElectricRateElements";
import calcBillingDeterminants from "./calcBillingDeterminants";

const calcElectricityCosts = (
  usage,
  rateName,
  years,
  workdaysPerWeek,
  inflation
) => {
  const dets = calcBillingDeterminants(usage, workdaysPerWeek);

  return years.map((year, idx) => {
    const els = getElectricRateElements(rateName, year);
    const inflationRate = Math.pow(1 + inflation, idx);

    const fixed =
      inflationRate *
      (els.customerChargePerDay * dets.customerChargePerDay +
        els.customerChargePerMonth * dets.customerChargePerMonth);

    const demand = inflationRate * (els.demandCharge * dets.demandCharge);

    const energy =
      inflationRate *
      (els.summerOnPeak * dets.summerOnPeak +
        els.summerMidPeak * dets.summerMidPeak +
        els.summerOffPeak * dets.summerOffPeak +
        els.winterMidPeak * dets.winterMidPeak +
        els.winterOffPeak * dets.winterOffPeak +
        els.winterSuperoffPeak * dets.winterSuperoffPeak +
        els.threePhaseServiceCharge * dets.totalUsage +
        els.taxes * dets.totalUsage);

    return {
      fixed,
      demand,
      energy,
      total: fixed + demand + energy,
    };
  });
};

export default calcElectricityCosts;
