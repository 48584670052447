import React from "react";
import { useToggle } from "@bellawatt/react-hooks";
import { colors, Row, Col } from "../styles";
import Link from "./Link";
import Toggler from "../styles/Toggler";

const Title = () => (
  <div
    style={{
      fontSize: "20px",
      color: colors.white,
      border: `0px solid ${colors.blueGrey}`,
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      fontWeight: "bold",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    Electric Fleet Fuel Savings Calculator
  </div>
);

const ToggleButton = props => (
  <button
    style={{
      border: "none",
      color: "#FFF",
      background: "none",
      height: "63px",
      float: "right",
      width: "63px"
    }}
    {...props}
  >
    <span style={{display: 'none'}}>Toggle Mobile Menu</span>
    <Toggler height="100%" width="100%" />
  </button>
);

const TopNav = ({ active, condensed = false, transparent = false }) => {
  const [mobileLinksVisible, toggle] = useToggle(false);

  return (
    <Row
      style={{
        display: "flex",
        minHeight: "63px",
        backgroundColor: transparent ? "transparent" : colors.primary,
        borderBottom: `1px solid ${colors.blueGrey}`
      }}
    >
      <Col>
        <Row fullHeight>
          <Col valign md="4" style={{ color: "#FFF" }}>
            <img
              src="/images/sce_white.png"
              alt="Southern California Edison Logo"
              style={{ maxWidth: "180px", padding: "10px 0" }}
            />
          </Col>
          <Col hideMobile md="8">
            {!condensed && <Title />}
          </Col>
        </Row>
      </Col>

      <Col onlyMobile>
        <ToggleButton onClick={toggle} />
      </Col>

      <Col xs="12" sm hideMobile={!mobileLinksVisible}>
        <Row fullHeight>
          <Col hideMobile md="4"></Col>
          <Col xs="12" sm valign>
            <Link href="/" active={active === "home"}>
              Home
            </Link>
          </Col>
          <Col xs="12" sm valign>
            <Link href="/calculator" active={active === "calculator"}>
              Savings Calculator
            </Link>
          </Col>
          <Col xs="12" sm valign>
            <Link href="/faq" active={active === "faq"}>
              FAQ
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TopNav;
