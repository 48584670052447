const FormatAsDollars = val => {
  return (
    "$" +
    Math.round(val)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

const FormatAsHundredsOfDollars = val => {
  return (
    "~$" +
    (Math.round(val / 100) * 100)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

const FormatAsThousandsOfDollars = val => {
  return (
    "$" +
    Math.round(val / 1000)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    "k"
  );
};
const FormatAsDollarsRounded = val => {
  const numZeros = 3;

  const output =
    Math.round(val).toString().length > numZeros
      ? Number(val.toPrecision(Math.round(val).toString().length - 3))
      : Math.round(Number(val));

  return "$" + output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const FormatAsCents = val => {
  return (
    "$" +
    parseFloat(val)
      .toFixed(2)
      .toString()
  );
};

const FormatAsTwoDecimals = val => {
  return parseFloat(val)
    .toFixed(2)
    .toString();
};

const FormatAsCentsDetailed = val => {
  return (
    "$" +
    parseFloat(val)
      .toFixed(4)
      .toString()
  );
};

const FormatAsDollarsPerGal = val => {
  return (
    "$" +
    parseFloat(val)
      .toFixed(2)
      .toString() +
    "/gal"
  );
};

const FormatAsPercent = val => {
  return parseFloat(val).toFixed(2) + "%";
};

const FormatAsPercentRounded = val => {
  return parseFloat(val).toFixed(0) + "%";
};

const FormatAsInt = val => {
  return parseFloat(val).toFixed(0);
};

const FormatAsThousands = val => {
  return Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const FormatAsTime = time => {
  var hh = Math.floor(time);
  var mm = Math.round((time - hh) * 12) * 5;
  let renderHours = hh === 0 ? "" : hh + " hr";
  let renderMinutes = mm === 0 ? "" : " " + mm + " min";
  return renderHours + renderMinutes;
};

const FormatCarName = car => {
  return car
    ? (car.make + " " + car.model + " " + car.model_variant).toString().trim()
    : null;
};

export {
  FormatAsDollars,
  FormatAsHundredsOfDollars,
  FormatAsThousandsOfDollars,
  FormatAsDollarsRounded,
  FormatAsCents,
  FormatAsTwoDecimals,
  FormatAsCentsDetailed,
  FormatAsDollarsPerGal,
  FormatAsPercent,
  FormatAsPercentRounded,
  FormatAsInt,
  FormatAsThousands,
  FormatAsTime,
  FormatCarName
};
