import React from "react";
import {
  Modal as BaseModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import { colors } from ".";

const Modal = ({
  title,
  isOpen,
  toggle,
  children,
  fade = true,
  onSubmit,
  size,
  status,
  onCancel,
  submit = "Save",
  cancel = "Cancel"
}) => (
  <BaseModal isOpen={isOpen} toggle={toggle} size={size} fade={fade}>
    <ModalHeader toggle={toggle}>
      {status && (
        <span
          style={{ fontSize: "14px", color: colors.lightGrey }}
          className="float-left"
        >
          {status}
        </span>
      )}
      <div
        style={{
          textTransform: "uppercase",
          color: colors.darkGrey,
          fontWeight: 500
        }}
      >
        {title}
      </div>
    </ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Button color="default" onClick={onCancel || toggle}>
        {cancel}
      </Button>
      <Button
        color="primary"
        onClick={onSubmit}
        style={{
          color: colors.primary,
          backgroundColor: colors.secondary,
          borderColor: colors.secondary
        }}
      >
        {submit}
      </Button>
    </ModalFooter>
  </BaseModal>
);

export default Modal;
