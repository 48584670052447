export default {
  "Class 3: Walk In": {
    vehicleType: "Class 3: Walk In",
    imgSource: "class_3_walk_in.png",
    electricRangeInMiles: 120,
    capacityInKwh: 86,
    maxChargeRateInKw: 50,
    conventionalDieselMpg: 7.4,
  },
  "Class 3: Truck": {
    vehicleType: "Class 3: Truck",
    imgSource: "class_3_truck.png",
    electricRangeInMiles: 200,
    capacityInKwh: 120,
    maxChargeRateInKw: 96,
    conventionalDieselMpg: 7.4,
  },
  "Class 4: Conventional Van": {
    vehicleType: "Class 4: Conventional Van",
    imgSource: "class_4_van.png",
    electricRangeInMiles: 150,
    capacityInKwh: 118,
    maxChargeRateInKw: 16.8,
    conventionalDieselMpg: 7.4,
  },
  "Class 4: School Bus": {
    vehicleType: "Class 4: School Bus",
    imgSource: "class_4_school_bus.png",
    electricRangeInMiles: 115,
    capacityInKwh: 105,
    maxChargeRateInKw: 16.8,
    conventionalDieselMpg: 7.7,
  },
  "Class 4: Shuttle Bus": {
    vehicleType: "Class 4: Shuttle Bus",
    imgSource: "class_4_shuttle_bus.png",
    electricRangeInMiles: 110,
    capacityInKwh: 130,
    maxChargeRateInKw: 50,
    conventionalDieselMpg: 7,
  },
  "Class 5: Cab Chassis": {
    vehicleType: "Class 5: Cab Chassis",
    imgSource: "class_5_truck.png",
    electricRangeInMiles: 155,
    capacityInKwh: 145,
    maxChargeRateInKw: 150,
    conventionalDieselMpg: 6.6,
  },
  "Class 6: Box Truck": {
    vehicleType: "Class 6: Box Truck",
    imgSource: "class_6_box_truck.png",
    electricRangeInMiles: 163,
    capacityInKwh: 153,
    maxChargeRateInKw: 100,
    conventionalDieselMpg: 7.2,
  },
  "Class 6: School Bus": {
    vehicleType: "Class 6: School Bus",
    imgSource: "class_6_school_bus.png",
    electricRangeInMiles: 100,
    capacityInKwh: 127,
    maxChargeRateInKw: 17.8,
    conventionalDieselMpg: 6,
  },
  "Class 7: School Bus": {
    vehicleType: "Class 7: School Bus",
    imgSource: "class_7_school_bus.png",
    electricRangeInMiles: 155,
    capacityInKwh: 220,
    maxChargeRateInKw: 19.2,
    conventionalDieselMpg: 5,
  },
  "Class 8: City Transit Bus (35 ft)": {
    vehicleType: "Class 8: City Transit Bus (35 ft)",
    imgSource: "class_8_transit_bus_35_ft.png",
    electricRangeInMiles: 212,
    capacityInKwh: 440,
    maxChargeRateInKw: 132,
    conventionalDieselMpg: 5,
  },
  "Class 8: City Transit Bus (40 ft)": {
    vehicleType: "Class 8: City Transit Bus (40 ft)",
    imgSource: "class_8_transit_bus_40_ft.png",
    electricRangeInMiles: 328,
    capacityInKwh: 660,
    maxChargeRateInKw: 132,
    conventionalDieselMpg: 4.3,
  },
  "Class 8: City Transit Bus (60 ft)": {
    vehicleType: "Class 8: City Transit Bus (60 ft)",
    imgSource: "class_8_transit_bus_60_ft.png",
    electricRangeInMiles: 135,
    capacityInKwh: 466,
    maxChargeRateInKw: 145.625,
    conventionalDieselMpg: 4.1,
  },
  "Class 8: Truck": {
    vehicleType: "Class 8: Truck",
    imgSource: "class_8_truck.png",
    electricRangeInMiles: 328,
    capacityInKwh: 660,
    maxChargeRateInKw: 132,
    conventionalDieselMpg: 4.3,
  },
  "Class 8: Box Truck": {
    vehicleType: "Class 8: Box Truck",
    imgSource: "class_8_box_truck.png",
    electricRangeInMiles: 172,
    capacityInKwh: 272,
    maxChargeRateInKw: 150,
    conventionalDieselMpg: 6.4,
  }
};
