import React from "react";
import BaseGraph from "./BaseGraph";
import { colors } from "../styles";
import { FormatAsDollars, FormatAsThousandsOfDollars } from "../utils/Format";
import A from "../calcs/ASSUMPTIONS";

const ElectricityVsDieselFuelCosts = ({
  electricityCosts,
  dieselCosts,
  isTru
}) => {
  const data = {
    labels: electricityCosts.map((_, idx) => A.GRAPH_YR_LABELS[idx]),
    datasets: [
      ...(isTru
        ? [
            {
              label: "Diesel For Hybrid",
              data: electricityCosts.map(costs => costs.dieselForElectricTru),
              backgroundColor: colors.chart.blue,
              stack: "stack 1"
            }
          ]
        : []),
      {
        label: "Electricity",
        data: electricityCosts.map(
          costs => costs.fixed + costs.demand + costs.energy
        ),
        backgroundColor: colors.chart.yellow,
        stack: "stack 1"
      },

      {
        label: "Diesel",
        data: dieselCosts.map(costs => costs),
        backgroundColor: colors.darkGrey,
        stack: "stack 2"
      }
    ]
  };

  return (
    <>
      <BaseGraph
        data={data}
        tooltip="Comparison of annual fueling costs of a diesel vehicle to an electric vehicle"
        stacked
        title="Electricity vs. Diesel Fuel Costs"
        yFormatCallback={FormatAsThousandsOfDollars}
        tooltipCallback={FormatAsDollars}
      />
    </>
  );
};

export default ElectricityVsDieselFuelCosts;
