import React, { useState } from "react";
import { useLocalStorage } from "../hooks";
import { Modal } from "../styles";
import { VehicleDetails, RouteSchedule } from "./content";
import ContactModal from "./ContactModal";

const sections = [VehicleDetails, RouteSchedule];

const FirstVisit = ({ isOpen, toggle }) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [, setHasSubmittedInfo] = useLocalStorage("hasSubmittedInfo");
  const onContactModal = currentSectionIndex === sections.length;

  const CurrentSection = sections[currentSectionIndex];

  const nextSection = () => {
    // ask the section if it wants us to do anything here
    // CurrentSection.onSubmit && CurrentSection.onSubmit();

    if (currentSectionIndex < sections.length) {
      setCurrentSectionIndex(current => current + 1);
    } else {
      complete();
    }
  };

  const complete = () => {
    setHasSubmittedInfo(true);
    toggle();
  };

  const previousSection = () => {
    setCurrentSectionIndex(current => (current > 0 ? current - 1 : 0));
  };

  return onContactModal ? (
    <ContactModal isOpen={isOpen} toggle={toggle} firstVisit />
  ) : (
    <Modal
      title={CurrentSection.title}
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      status={`Step ${currentSectionIndex + 1} of ${sections.length + 1}`}
      onSubmit={nextSection}
      onCancel={previousSection}
      submit={"Next"}
      cancel="Back"
    >
      <div style={{ maxWidth: "80%", margin: "auto" }}>
        <CurrentSection toggle={toggle} />
      </div>
    </Modal>
  );
};

export default FirstVisit;
