import React from "react";
import { useToggle } from "@bellawatt/react-hooks";
import Header from "../header/Header";
import Main from "../main/Main";
import Footer from "../header/Footer";
import FirstVisitModal from "../modals/FirstVisit";

const Calculator = ({ isFirstVisit }) => {
  const [modalOpen, toggleModal] = useToggle(isFirstVisit);
  return (
    <>
      <Header />
      <Main />
      <Footer />
      <FirstVisitModal isOpen={modalOpen} toggle={toggleModal} />
    </>
  );
};

export default Calculator;
