import React, { useContext } from "react";
import { useToggle, useDebounceEffect } from "@bellawatt/react-hooks";
import InputContext from "../context";
import { useLocalStorage } from "../hooks";
import ContactModal from "../modals/ContactModal";
import { colors, Row, Col } from "../styles";
import { FormatAsDollarsRounded } from "../utils/Format";

const styles = {
  minHeight: "120px",
  backgroundColor: colors.secondary,
  color: colors.primary,
  fontSize: "33px"
};

const Button = ({ onClick, children, width = "170px" }) => (
  <button
    onClick={onClick}
    style={{
      width,
      height: "90px",
      backgroundColor: colors.primary,
      color: colors.secondary,
      margin: "20px",
      textTransform: "uppercase",
      fontSize: "16px",
      border: "none",
      display: "flex",
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    {children}
  </button>
);

const DesktopBanner = ({ openModal, bannerText }) => (
  <Row style={styles}>
    <Col valign xs="12" sm={{ size: "7", offset: 3 }}>
      {bannerText}
    </Col>
    <Col valign xs="12" sm="2">
      <Button onClick={openModal}>Find out how</Button>
    </Col>
  </Row>
);

const MobileBanner = ({ openModal, bannerText }) => (
  <Row
    style={{
      ...styles,
      fontSize: "22px",
      textAlign: "center",
      paddingTop: "20px"
    }}
  >
    <Col valign xs="12" sm={{ size: "7", offset: 3 }}>
      {bannerText}
    </Col>
    <Col valign xs="12" sm="2">
      <Button onClick={openModal} width="100%">
        Find out how
      </Button>
    </Col>
  </Row>
);

const Banner = ({ years, outputs }) => {
  const { setInput, ...inputs } = useContext(InputContext);
  const [modalOpen, toggle] = useToggle(false);
  const [hasSubmittedInfo, setHasSubmittedInfo] = useLocalStorage(
    "hasSubmittedInfo"
  );

  useDebounceEffect(
    () => {
      if (hasSubmittedInfo) {
        return;
      }

      setHasSubmittedInfo(true);
      toggle();
    },
    30000,
    [inputs]
  );

  const bannerText = !outputs.modelErrors.hasErrors ? (
    <span>
      Over {years} years, you could save approximately{" "}
      <strong>{FormatAsDollarsRounded(outputs.lifetime.netBenefit)}</strong> in
      fuel costs by going electric.
    </span>
  ) : null;

  return (
    <>
      <div className="d-none d-sm-block">
        <DesktopBanner openModal={toggle} bannerText={bannerText} />
      </div>
      <div className="d-block d-sm-none">
        <MobileBanner openModal={toggle} bannerText={bannerText} />
      </div>
      <ContactModal isOpen={modalOpen} toggle={toggle} />
    </>
  );
};

export default Banner;
