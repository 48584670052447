import React from "react";
import { colors, Tooltip } from "../styles";

// this is set in the CSS since it's accessed via a psuedo selector
const thumbWidth = 24; // actual width minus the borders

const Bubble = ({ children, currentPosition, positionOffset, prefix }) => (
  <div
    className="slider-bubble"
    style={{
      left: `calc(${currentPosition}% - ${positionOffset}px)`
    }}
  >
    {prefix}
    {children}
  </div>
);

const Slider = ({
  label,
  color = colors.blueGrey,
  min,
  max,
  tooltip,
  value,
  prefix = "",
  onChange,
  step = 1
}) => {
  const currentPosition = ((value - min) / (max - min)) * 100;
  const positionOffset =
    Math.round((thumbWidth * currentPosition) / 100) - thumbWidth / 2;

  return (
    <div style={{ margin: "15px 0 35px", color, width: "100%" }}>
      <label style={{ width: "100%", position: "relative" }}>
        {label}&nbsp;
        {tooltip && <Tooltip>{tooltip}</Tooltip>}
        <input
          min={min}
          step={step}
          max={max}
          value={value}
          onChange={e => onChange && onChange(e.currentTarget.value)}
          style={{ paddingTop: "25px" }}
          type="range"
          className="custom-range"
        />
        <Bubble
          currentPosition={currentPosition}
          positionOffset={positionOffset}
          prefix={prefix}
        >
          {value}
        </Bubble>
      </label>
      <div className="clearfix">
        <span className="float-left">
          {prefix}
          {min}
        </span>
        <span className="float-right">
          {prefix}
          {max}
        </span>
      </div>
    </div>
  );
};

export default Slider;
