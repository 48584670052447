export default [
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
  {
    fixed_perday: 0.278,
    fixed_permonth: 0,
    demand_charge: 0,
    energy_summer_on: 0.6609,
    energy_summer_mid: 0.40084,
    energy_summer_off: 0.25369,
    energy_winter_mid: 0.47493,
    energy_winter_off: 0.27445,
    energy_winter_superoff: 0.14253,
    energy_threephaseserivce: 0.046,
  },
];
