import React from "react";
import { Row, Col } from "reactstrap";
import TopNav from "./TopNav";
import PrimaryInputs from "./PrimaryInputs";

const Header = () => (
  <>
    <div className="d-print-none">
      <Row>
        <Col>
          <TopNav active="calculator" />
        </Col>
      </Row>
      <Row>
        <Col>
          <PrimaryInputs />
        </Col>
      </Row>
    </div>
    <div className="d-print-block d-none">
      <h3>SCE Fleet Fuel Calculator</h3>
    </div>
  </>
);

export default Header;
