export default [
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 0,
    energy_summer_on: 0.71569,
    energy_summer_mid: 0.39656,
    energy_summer_off: 0.23417,
    energy_winter_mid: 0.45228,
    energy_winter_off: 0.25133,
    energy_winter_superoff: 0.12413,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 0,
    energy_summer_on: 0.71569,
    energy_summer_mid: 0.39656,
    energy_summer_off: 0.23417,
    energy_winter_mid: 0.45228,
    energy_winter_off: 0.25133,
    energy_winter_superoff: 0.12413,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 3.64297,
    energy_summer_on: 0.63209,
    energy_summer_mid: 0.31296,
    energy_summer_off: 0.23112,
    energy_winter_mid: 0.36868,
    energy_winter_off: 0.24828,
    energy_winter_superoff: 0.1582,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 7.28595,
    energy_summer_on: 0.61941,
    energy_summer_mid: 0.30028,
    energy_summer_off: 0.21845,
    energy_winter_mid: 0.356,
    energy_winter_off: 0.2356,
    energy_winter_superoff: 0.14553,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 10.92892,
    energy_summer_on: 0.60674,
    energy_summer_mid: 0.28761,
    energy_summer_off: 0.20577,
    energy_winter_mid: 0.34333,
    energy_winter_off: 0.22293,
    energy_winter_superoff: 0.13285,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 14.5719,
    energy_summer_on: 0.59406,
    energy_summer_mid: 0.27493,
    energy_summer_off: 0.1931,
    energy_winter_mid: 0.33065,
    energy_winter_off: 0.21025,
    energy_winter_superoff: 0.12017,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 18.21487,
    energy_summer_on: 0.58139,
    energy_summer_mid: 0.26225,
    energy_summer_off: 0.18042,
    energy_winter_mid: 0.31797,
    energy_winter_off: 0.19758,
    energy_winter_superoff: 0.1075,
    energy_threephaseserivce: 0,
  },
  {
    fixed_perday: 0,
    fixed_permonth: 220.78,
    demand_charge: 21.85785,
    energy_summer_on: 0.56871,
    energy_summer_mid: 0.24958,
    energy_summer_off: 0.16774,
    energy_winter_mid: 0.3053,
    energy_winter_off: 0.1849,
    energy_winter_superoff: 0.09482,
    energy_threephaseserivce: 0,
  },
];
