import times from "lodash/times";
import A from "./ASSUMPTIONS";

// unmanaged charging: apply max load starting at 5p until it's 0
const calcUnmanagedCharging = (evseCount, maxChargeRate, dailyCharge) => {
  let unmanagedChargingKw = times(24, () => 0);
  let unmanagedKwRemaining = evseCount * dailyCharge;
  const chargeRate = maxChargeRate * evseCount;

  for (let i = A.UNMANAGED_CHARGING_HOUR_START; i < 24; i++) {
    if (unmanagedKwRemaining > 0) {
      unmanagedChargingKw[i] = Math.min(chargeRate, unmanagedKwRemaining);
      unmanagedKwRemaining -= chargeRate;
    }
  }
  for (let i = 0; i < A.UNMANAGED_CHARGING_HOUR_END; i++) {
    if (unmanagedKwRemaining > 0) {
      unmanagedChargingKw[i] = Math.min(chargeRate, unmanagedKwRemaining);
      unmanagedKwRemaining -= chargeRate;
    }
  }

  return unmanagedChargingKw;
};

export default calcUnmanagedCharging;
