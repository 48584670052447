const TRU = "Transport Refrigeration Unit (TRU)";
const GRID = "Grid";
const ZERO_CI = "0 Carbon Intensity";
const CUSTOM = "Custom";
const RECOMMEND = "Recommend";

export default {
  TRU,
  GRID,
  ZERO_CI,
  CUSTOM,
  RECOMMEND,
};
