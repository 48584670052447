import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { colors } from "../styles";
import Caret from "./Caret";

const SectionTitleButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    style={{
      height: "40px",
      width: "100%",
      border: 0,
      background: "none",
      textAlign: "left",
      textTransform: "uppercase",
      outline: 0,
      color: colors.primary,
      fontWeight: "bold",
      fontSize: "18px"
    }}
  >
    {children}
  </button>
);

const Accordion = ({ title, children = [] }) => {
  const [openSections, setOpenSections] = useState(
    React.Children.map(
      children,
      child => child && (child.props.defaultOpen || false)
    )
  );
  const anyClosed = openSections.some(section => !section);
  const openCloseAll = () =>
    setOpenSections(current => current.map(_ => anyClosed));

  return (
    <Card
      style={{
        backgroundColor: colors.white,
        borderRadius: "4px",
        boxShadow: "0 16px 34px 7px rgba(121,121,121,0.1)"
      }}
    >
      <CardBody>
        <span
          style={{
            color: colors.blueGrey,
            fontWeight: 500,
            textTransform: "uppercase"
          }}
        >
          {title}
        </span>
        <button
          style={{ border: 0, color: colors.green, background: "none" }}
          onClick={openCloseAll}
          className="float-right"
        >
          {anyClosed ? "Open All" : "Close All"}
        </button>
        {React.Children.map(
          children,
          (child, idx) =>
            child &&
            React.cloneElement(child, {
              onToggle: () => {
                setOpenSections(current =>
                  Object.assign([...current], { [idx]: !current[idx] })
                );
              },
              isOpen: openSections[idx]
            })
        )}
      </CardBody>
    </Card>
  );
};

const AccordionSection = ({
  title,
  children,
  isHidden = false,
  isOpen,
  onToggle
}) => {
  return isHidden ? null : (
    <div>
      <hr style={{ marginLeft: "-20px", marginRight: "-20px" }} />
      <SectionTitleButton onClick={() => onToggle && onToggle()}>
        {title}
        <Caret className="float-right" direction={isOpen ? "up" : "down"} />
      </SectionTitleButton>
      <div style={{ padding: "0 10px", paddingTop: 0 }}>
        {isOpen && children}
      </div>
    </div>
  );
};

Accordion.Section = AccordionSection;

export default Accordion;
