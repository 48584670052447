export default {
  "Level 2 - 7.2 kW": {
    evseType: "Level 2 - 7.2 kW",
    level: "Level 2",
    chargingPorts: 1,
    chargeRatePerPortInKw: 7.2,
    chargingEfficiency: 95,
    chargeRatePerPortCutoff: (7.2 * 95) / 100
  },
  "Level 2 - 9.6 kW": {
    evseType: "Level 2 - 9.6 kW",
    level: "Level 2",
    chargingPorts: 1,
    chargeRatePerPortInKw: 9.6,
    chargingEfficiency: 95,
    chargeRatePerPortCutoff: (9.6 * 95) / 100
  },
  "Level 2 - 12.0 kW": {
    evseType: "Level 2 - 12.0 kW",
    level: "Level 2",
    chargingPorts: 1,
    chargeRatePerPortInKw: 12.0,
    chargingEfficiency: 95,
    chargeRatePerPortCutoff: (12.0 * 95) / 100
  },
  "Level 2 - 15.4 kW": {
    evseType: "Level 2 - 15.4 kW",
    level: "Level 2",
    chargingPorts: 1,
    chargeRatePerPortInKw: 15.4,
    chargingEfficiency: 95,
    chargeRatePerPortCutoff: (15.4 * 95) / 100
  },
  "Level 2 - 16.8 kW": {
    evseType: "Level 2 - 16.8 kW",
    level: "Level 2",
    chargingPorts: 1,
    chargeRatePerPortInKw: 16.8,
    chargingEfficiency: 93,
    chargeRatePerPortCutoff: (16.8 * 93) / 100
  },
  "Level 2 - 19.2 kW": {
    evseType: "Level 2 - 19.2 kW",
    level: "Level 2",
    chargingPorts: 1,
    chargeRatePerPortInKw: 19.2,
    chargingEfficiency: 95,
    chargeRatePerPortCutoff: (19.2 * 95) / 100
  },
  "DCFC - 50 kW": {
    evseType: "DCFC - 50 kW",
    level: "DCFC",
    chargingPorts: 1,
    chargeRatePerPortInKw: 50,
    chargingEfficiency: 94,
    chargeRatePerPortCutoff: (50 * 94) / 100
  },
  "DCFC - 100 kW": {
    evseType: "DCFC - 100 kW",
    level: "DCFC",
    chargingPorts: 1,
    chargeRatePerPortInKw: 100,
    chargingEfficiency: 92,
    chargeRatePerPortCutoff: (100 * 92) / 100
  },
  "DCFC - 160 kW": {
    evseType: "DCFC - 160 kW",
    level: "DCFC",
    chargingPorts: 1,
    chargeRatePerPortInKw: 160,
    chargingEfficiency: 95,
    chargeRatePerPortCutoff: (160 * 95) / 100
  },
  "DCFC - 200 kW": {
    evseType: "DCFC - 200 kW",
    level: "DCFC",
    chargingPorts: 1,
    chargeRatePerPortInKw: 200,
    chargingEfficiency: 92,
    chargeRatePerPortCutoff: (200 * 92) / 100
  }
};
