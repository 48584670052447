import React from "react";
import { useToggle } from "@bellawatt/react-hooks";
import { colors } from ".";

const Question = ({ children, open, onClick }) => (
  <button
    style={{
      color: colors.blueGrey,
      fontSize: "24px",
      width: "100%",
      padding: 0,
      textAlign: "left",
      background: "none",
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }}
    onClick={onClick}
  >
    {children}
    <QuestionIcon open={open} />
  </button>
);

const QuestionIcon = ({ open }) => (
  <div
    style={{
      height: "24px",
      width: "24px",
      minHeight: "24px",
      minWidth: "24px",
      border: `1px solid ${colors.blueGrey}`,
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    {open ? "-" : "+"}
  </div>
);

const Answer = ({ children }) => (
  <div
    style={{
      color: colors.lightGrey,
      fontSize: "18px",
      margin: "10px 0"
    }}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

const FaqItem = ({ question, answer }) => {
  const [open, toggle] = useToggle(false);

  return (
    <div>
      <Question onClick={toggle} open={open}>
        {question}
      </Question>
      {open && <Answer>{answer}</Answer>}
      <hr style={{ marginTop: "20px" }} />
    </div>
  );
};

export default FaqItem;
