import React from "react";
import { Row, Col } from "../styles";
import { colors } from "../styles";

const TopNav = () => (
  <Row
    style={{
      display: "flex",
      minHeight: "63px",
      backgroundColor: colors.primary,
      borderBottom: `1px solid ${colors.blueGrey}`
    }}
  >
    <Col>
      <Row fullHeight>
        <Col valign md="4" style={{ color: "#FFF" }}>
          <img
            src="/images/sce_white.png"
            alt="Southern California Edison Logo"
            style={{ maxWidth: "180px", padding: "10px 0" }}
          />
        </Col>
      </Row>
    </Col>
  </Row>
)

const ErrorContainer = ({ children }) => (
  <div
    style={{
      width: "100%",
      borderRadius: "8px",
      backgroundColor: colors.white,
      boxShadow: "0 16px 34px 7px rgba(121,121,121,0.1)",
      padding: "72px 40px",
      marginBottom: "40px"
    }}
  >
    {children}
  </div>
);

const Header = ({ children }) => (
  <div
    style={{
      fontSize: "28px",
      fontWeight: 500,
      color: colors.darkGrey,
      margin: "40px 0"
    }}
  >
    {children}
  </div>
);

const ErrorPage = () => (
  <>
    <TopNav />
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <Header>Oops, looks like something went wrong.</Header>
        <ErrorContainer>
          <p>
            Don't worry, our development team has already been notified.
            Please refresh your browser or <a href="/calculator">click here to try again</a>. 
          </p>
          
          <p>If the problem persists, check back later.</p>
        </ErrorContainer>
      </Col>
    </Row>
  </>
);

export default ErrorPage;
