import React from "react";
import BaseGraph from "./BaseGraph";
import { colors } from "../styles";
import { FormatAsDollars, FormatAsThousandsOfDollars } from "../utils/Format";
import A from "../calcs/ASSUMPTIONS";

const ManagedVsUnmanagedCharging = ({
  managedElectricityCosts,
  unmanagedElectricityCosts
}) => {
  const data = {
    labels: managedElectricityCosts.map((_, idx) => A.GRAPH_YR_LABELS[idx]),
    datasets: [
      {
        label: "Managed Charging",
        data: managedElectricityCosts.map(costs => costs.total),
        backgroundColor: colors.chart.yellow
      },
      {
        label: "Unmanaged Charging",
        data: unmanagedElectricityCosts.map(costs => costs.total),
        backgroundColor: colors.chart.darkBlue
      }
    ]
  };

  return (
    <>
      <BaseGraph
        data={data}
        tooltip="This graph illustrates the opportunity of charging during off-peak times and at a lower power level (managed charging) to achieve same recharge levels."
        title="Managed vs Unmanaged Charging"
        yFormatCallback={FormatAsThousandsOfDollars}
        tooltipCallback={FormatAsDollars}
      />
    </>
  );
};

export default ManagedVsUnmanagedCharging;
