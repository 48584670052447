import React from "react";

const Disclaimer = () => (
  <div className="small mt-5">
    <p>
      This rate analysis is based on applicable Southern California Edison (SCE)
      Electric Vehicle Rates and is provided to illustrate a high-level
      comparison to conventional fuel costs and may not include all applicable
      rate options or available savings. This analysis is based on information
      you provided and certain assumptions for charging patterns, usage, and
      applicable rate factors. SCE can neither predict nor guarantee any actual
      cost savings or increases due to changes to usage variables or applicable
      rate factors such as: charging assumptions, operating factors, equipment,
      kVar charges, weather patterns, service voltage, firm service levels,
      taxes, and added facilities charges. These rates are based on secondary
      voltage (less than 2 kV) and bundled service.
    </p>
  </div>
);

export default Disclaimer;
