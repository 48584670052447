import React from "react";
import { colors, Tooltip } from "../styles";
import { Bar } from "react-chartjs-2";
import merge from "lodash/merge";

const backupFormat = function(value) {
  return value;
};

const backupTooltip = function(value, label) {
  return `${label}: ${value}`;
};

const GraphTitle = ({ children }) => (
  <div
    style={{
      fontSize: "20px",
      color: colors.darkGrey,
      fontWeight: 500
    }}
    className="mb-n2"
  >
    {children}
  </div>
);

const BaseGraph = ({
  options,
  title,
  tooltip,
  stacked = false,
  xAxisOptions = {},
  yFormatCallback = backupFormat,
  tooltipCallback = backupTooltip,
  ...props
}) => {
  const baseOptions = {
    legend: {
      align: "end",
      labels: {
        boxWidth: 15
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipCallback(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
            data.datasets[tooltipItem.datasetIndex].label || ""
          );
        }
      }
    },
    scales: {
      xAxes: [
        merge(
          {
            stacked,
            gridLines: {
              display: false
            }
          },
          xAxisOptions
        )
      ],
      yAxes: [
        {
          stacked,
          ticks: {
            fontSize: 16,
            autoSkipPadding: 40,
            beginAtZero: true,
            callback: yFormatCallback
          }
        }
      ]
    }
  };

  const mergedOptions = merge(baseOptions, options);

  return (
    <div className="my-sm-5 my-4 mx-sm-2">
      <GraphTitle>
        {title}&nbsp;
        {tooltip && <Tooltip>{tooltip}</Tooltip>}
      </GraphTitle>
      <Bar {...props} options={mergedOptions} />
    </div>
  );
};

export default BaseGraph;
