import React from "react";
import BaseGraph from "./BaseGraph";
import { colors } from "../styles";
import { FormatAsCents } from "../utils/Format";
import A from "../calcs/ASSUMPTIONS";

const FuelSavingsPerMile = ({ lcfsPerMile, fuelSavingsPerMile }) => {
  const data = {
    labels: lcfsPerMile.map((_, idx) => A.GRAPH_YR_LABELS[idx]),
    datasets: [
      {
        label: "Fuel",
        data: fuelSavingsPerMile,
        backgroundColor: colors.chart.green
      },
      {
        label: "LCFS",
        data: lcfsPerMile,
        backgroundColor: colors.chart.orange
      }
    ]
  };

  return (
    <BaseGraph
      title="Fuel savings per mile"
      data={data}
      yFormatCallback={FormatAsCents}
      tooltipCallback={FormatAsCents}
      stacked
    />
  );
};

export default FuelSavingsPerMile;
