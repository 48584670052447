import React, { useContext } from "react";
import moment from 'moment';
import InputContext from "../context";
import { Section, Label, Value } from "./KeyOutputs";
import titleizeCamelCase from '../utils/titleizeCamelCase';

const formatValue = ({key, value}) => {
  if (key !== 'chargingSchedule') return value;

  return value.map(hour => moment(hour, 'H').format('ha')).join(', ');
}

const PrintInputs = () => {
  const { setInput, ...inputs } = useContext(InputContext);

  return (
    <div className="d-print-block d-none">
      {Object.keys(inputs).map(key => (
        <Section key={key}>
          <Label>{titleizeCamelCase(key)}</Label>
          <Value>{formatValue({key, value: inputs[key]})}</Value>
        </Section>
      ))}
    </div>
  );
};

export default PrintInputs;
