import React, { useContext } from "react";
import InputContext from "../context";
import { Row, Col } from "../styles";
import SecondaryInputs from "./SecondaryInputs";
import Banner from "./Banner";
import Graphs from "./Graphs";
import MobileSecondaryInputs from "./MobileSecondaryInputs";
import KeyOutputs from "./KeyOutputs";
import Disclaimer from "./Disclaimer";
import calcModel from "../calcs/calcModel";
import PrintInputs from "./PrintInputs";

const Main = () => {
  const { setInput, ...inputs } = useContext(InputContext);
  const outputs = calcModel(inputs);

  const graphs = !outputs.modelErrors.hasErrors ? (
    <>
      {!outputs.canChargeToFull && (
        <div class="alert alert-danger mt-5 text-center" role="alert">
          Warning: This configuration does not recharge the vehicle to 100%.
        </div>
      )}
      <Graphs outputs={outputs} />
    </>
  ) : (
    <div class="alert alert-danger mt-5 text-center" role="alert">
      {!outputs.modelErrors.evseIsAdequatelySized && (
        <span>
          EV charger may be insufficient in a scenario where vehicle battery is
          fully depleted. Please select{" "}
          {outputs.evseRecommended
            ? "a charger " +
              outputs.evseRecommended.chargeRatePerPortInKw +
              " kW or greater."
            : "a larger charger."}
        </span>
      )}
      {!outputs.modelErrors.canRecommendEvse && (
        <span>
          In this scenario, the required load per EVSE surpasses the largest
          EVSE option available in this tool (200 kW).
        </span>
      )}
      {!outputs.modelErrors.truOperatesLongerThanCharging && (
        <span>
          TRU operating hours should always be greater than the total number of
          charging hours.
        </span>
      )}
    </div>
  );

  return (
    <>
      <MobileSecondaryInputs outputs={outputs} />
      <Row className="d-print-none">
        <Col>
          <Banner outputs={outputs} years={inputs.vehicleLifetime} />
        </Col>
      </Row>
      <Row>
        <Col hideMobile md="3" className="secondary-input-container">
          <SecondaryInputs outputs={outputs} />
          <PrintInputs />
        </Col>
        <Col md="7" className="graph-container">
          {graphs}
        </Col>
        <Col md="2" className="key-outputs-container">
          <KeyOutputs outputs={outputs} />
        </Col>
      </Row>
      <Disclaimer />
    </>
  );
};

export default Main;
