import React from "react";
import { colors, Tooltip } from "../styles";

const Select = ({
  label,
  color = colors.blueGrey,
  value = "",
  tooltip,
  onChange,
  options,
  placeholder
}) => (
  <label style={{ color, margin: "15px 0", width: "100%" }}>
    {label}&nbsp;
    {tooltip && <Tooltip>{tooltip}</Tooltip>}
    <select
      className="form-control"
      style={{ marginTop: "10px" }}
      value={value}
      onChange={e => onChange(e.currentTarget.value)}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map(({ value, option }, idx) => (
        <option key={idx} value={value}>
          {option}
        </option>
      ))}
    </select>
  </label>
);

export default Select;
