import React from 'react';
import { useToggle } from '@bellawatt/react-hooks';
import { Modal, colors, Row, Col } from "../styles";
import SecondaryInputs from './SecondaryInputs';

const MoreFiltersButton = props => (
  <button
    style={{
      border: "1px solid #FFF",
      color: "#FFF",
      textTransform: "uppercase",
      margin: "20px",
      minWidth: "270px",
      backgroundColor: "transparent",
      fontWeight: "bold",
      height: "50px"
    }}
    {...props}
  >
    More Filters
  </button>
);

const SecondaryInputsModal = ({isOpen, toggle, outputs}) => (
  <Modal
    title="All Categories"
    submit="Apply"
    isOpen={isOpen}
    toggle={toggle}
    onSubmit={toggle}
  >
    <SecondaryInputs outputs={outputs} />
  </Modal>
);

const MobileSecondaryInputs = ({outputs}) => {
  const [open, toggle] = useToggle(false);

  return (
    <Row style={{backgroundColor: colors.primary}}>
      <Col xs="12" valign onlyMobile>
        <MoreFiltersButton onClick={toggle} />
        <SecondaryInputsModal outputs={outputs} isOpen={open} toggle={toggle} />
      </Col>
    </Row>
  )
}

export default MobileSecondaryInputs;
