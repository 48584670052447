import React, { useContext } from "react";
import InputContext from "../../context";
import { Select, Slider } from "../../inputs";
import { Header, Subheader, Label } from "./styles";
import VEHICLES from "../../data/VEHICLES";
import KEYS from "../../calcs/KEYS";

const VehicleLabel = () => (
  <Label>
    <Header>What type of electric vehicle are you considering?</Header>
    <Subheader>
      Compare a diesel and electric vehicle of selected vehicle type.
    </Subheader>
  </Label>
);

const AdoptLabel = () => (
  <Label>
    <Header>How many vehicles are you looking to adopt?</Header>
  </Label>
);

const VehicleDetails = () => {
  const { setInput, vehicleType, vehicleCount } = useContext(InputContext);

  return (
    <>
      <Select
        label={<VehicleLabel />}
        color="#FFF"
        options={[
          ...Object.keys(VEHICLES).map(value => ({
            key: value,
            option: value
          })),
          { option: KEYS.TRU, key: KEYS.TRU },
          { option: KEYS.CUSTOM, key: KEYS.CUSTOM }
        ]}
        value={vehicleType}
        onChange={vehicleType => setInput({ vehicleType })}
      />
      <Slider
        value={vehicleCount}
        onChange={vehicleCount => setInput({ vehicleCount })}
        label={<AdoptLabel />}
        min={1}
        max={300}
      />
    </>
  );
};

VehicleDetails.title = "Vehicle Details";

export default VehicleDetails;
