import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'
import ErrorPage from './pages/ErrorPage';

import "bootstrap/dist/css/bootstrap.min.css";

const config = Honeybadger.configure({
  api_key: 'b38380e1',
  environment: 'production',
});

ReactDOM.render(<ErrorBoundary honeybadger={config} ErrorComponent={ErrorPage}><App /></ErrorBoundary>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
