import React from "react";
import moment from "moment";
import times from "lodash/times";
import intersection from "lodash/intersection";
import chunk from "lodash/chunk";
import without from "lodash/without";
import union from "lodash/union";
import { CheckButton } from ".";
import { Row, Col, colors } from "../styles";
import A from "../calcs/ASSUMPTIONS";

const START = 8;
const HOURS = times(24, (num) => (num + START) % 24);

const ChargingSchedule = ({ value = [], onChange }) => {
  const isChecked = (hour) => value.includes(hour) !== false;
  const isEveryChecked = (HOURS) =>
    intersection(HOURS, value).length === HOURS.length;

  const check = (hour) => {
    if (isChecked(hour)) {
      onChange(without(value, hour));
    } else {
      onChange([...value, hour]);
    }
  };
  const checkEvery = (HOURS) => {
    if (isEveryChecked(HOURS)) {
      onChange(without(value, ...HOURS));
    } else {
      onChange(union(value, HOURS));
    }
  };

  return (
    <div>
      <label style={{ color: colors.blueGrey }}>
        What time of day will you charge your vehicles?
      </label>
      <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Col>
          <label style={{ color: colors.primary }}>
            <input
              checked={isEveryChecked(A.MIDDAY_HOURS)}
              onChange={() => checkEvery(A.MIDDAY_HOURS)}
              type="checkbox"
            />{" "}
            Mid-day
          </label>
        </Col>
        <Col>
          <label style={{ color: colors.primary }}>
            <input
              checked={isEveryChecked(A.OVERNIGHT_HOURS)}
              onChange={() => checkEvery(A.OVERNIGHT_HOURS)}
              type="checkbox"
            />{" "}
            Overnight
          </label>
        </Col>
      </Row>
      {chunk(HOURS, 4).map((row) => (
        <Row key={row}>
          {row.map((hour) => (
            <Col style={{ padding: "8px" }} key={hour}>
              <CheckButton
                onClick={() => check(hour)}
                checked={isChecked(hour)}
              >
                {moment(hour, "H").format("ha")}
              </CheckButton>
            </Col>
          ))}
        </Row>
      ))}
      <Row>
        <Col className="text-center">
          <button className="btn" onClick={() => onChange([])}>
            Clear All Hours
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default ChargingSchedule;
