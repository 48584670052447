import React from "react";

const Caret = ({ direction, className }) => {
  if (direction === "up") {
    return (
      <svg
        className={`bi bi-chevron-up ${className}`}
        width="2em"
        height="1.3em"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M9.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L10 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  if (direction === "down") {
    return (
      <svg
        className={`bi bi-chevron-down ${className}`}
        width="2em"
        height="1.3em"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M3.646 6.646a.5.5 0 01.708 0L10 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  console.error(
    `Caret should always have a direction prop with the value "up" or "down". You provided ${direction}`
  );
  return null;
};

export default Caret;
