import React, { useContext } from "react";
import InputContext from "../../context";
import { Slider } from "../../inputs";
import { Header, Subheader, Label as LabelContainer } from "./styles";
import KEYS from "../../calcs/KEYS";

const Label = ({ children }) => (
  <LabelContainer>
    <Header>{children}</Header>
  </LabelContainer>
);

const RouteSchedule = () => {
  const {
    setInput,
    vehicleType,
    milesPerVehiclePerDay,
    truDieselGalPerDay,
    workdaysPerWeek
  } = useContext(InputContext);

  const isTru = vehicleType === KEYS.TRU;

  return (
    <>
      {!isTru && (
        <Slider
          label={
            <Label>
              How many miles will each vehicle travel daily (on average)?
            </Label>
          }
          min={25}
          max={300}
          step={25}
          value={milesPerVehiclePerDay}
          onChange={milesPerVehiclePerDay =>
            setInput({ milesPerVehiclePerDay })
          }
        />
      )}
      {isTru && (
        <Slider
          label={
            <Label>
              How many gallons of diesel does the TRU consume daily?
            </Label>
          }
          min={1}
          max={20}
          value={truDieselGalPerDay}
          onChange={truDieselGalPerDay => setInput({ truDieselGalPerDay })}
        />
      )}
      <Slider
        label={
          <Label>
            How many days per week are the vehicle(s) used?
            <Subheader>Five (5) days or fewer assumes weekdays only.</Subheader>
          </Label>
        }
        min={1}
        max={7}
        value={workdaysPerWeek}
        onChange={workdaysPerWeek => setInput({ workdaysPerWeek })}
      />
    </>
  );
};

RouteSchedule.title = "Route Schedule";

export default RouteSchedule;
